import React from 'react'

function BtnGreen(props) {
    return (
        <button className="btn px-4 shadow btn_add" onClick={props.click}>{props.text}</button>
    )
}
// BtnGreen.defaultProps = {
//     text: "Button",
//     click: "null",
//   }
export default BtnGreen