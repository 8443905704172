import React, { useState} from 'react';

function ImageSlider({ images }) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    }

    const goToNext = () => {
        setCurrentIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 2));
    }

    return (
        <div>
            <img src={images[currentIndex]} alt="Slider" />
            <img src={images[currentIndex+1]} alt="Slider" />

            <button onClick={goToPrevious}>Previous</button>
            <button onClick={goToNext}>Next</button>
        </div>
    )

}
export default ImageSlider;