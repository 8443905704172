import React, { useContext, useEffect, useState } from "react";

import Footer from "../components/footer";
import Navbar from "../components/navbar";
import AddBookComic from "../components/subComonents/addBookModal";
import FloatingBtn from "../components/subComonents/floating-btn";
import { getDashboard } from "../api/dashboardRequest";
import { CheckContext, searchContext, userDetailsContext } from "../App";

import { Link } from "react-router-dom";
import CardBoot from "../components/subComonents/cardBoot";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import deleteBook from "../api/deleteBook";
import Modal from "react-bootstrap/Modal";
// import Preloader2 from '../components/subComonents/Preloader2';
import Preloader3 from "../components/subComonents/Preloader3";

// function Catalog() {
export const Catalog = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteIcon, showDeleteIcon] = useState(false);

  const [removeOverlay, setRemoveOverlay] = useState(false);
  const [dashboard, setDashboard] = useState([]); //1

  const [check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2] =
    useContext(CheckContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  // const [grayscale, setGrayscale] = useState(false);
  // const [deleteCheck, setDeleteCheck] = useState(false);
  debugger;
  const [selectedItems, setSelectedItems] = useState([]);
  // const [grayscale, setGrayscale] = useState(false);
  const userRole = userDetails?.data?.role;
  const userEmail = userDetails?.data?.email;
  // console.log("userRoleee", userRole)
  // const [isLoading3, setIsLoading2] = useState(false);

  const [keyword, setKeyword, searchResults, setSearchResults] =
    useContext(searchContext);
  const [searchResultStatus, setSearchResultStatus] = useState(false); //1
  // let searchResultStatus=false;

  const handleRemoveOverlay = () => {
    showDeleteIcon(true);
    setRemoveOverlay(true);
  };
  const sendRemovedList = () => {
    //debugger
    // alert("");
    setIsLoading2(true);
    deleteBook(selectedItems).then((res) => {
      //debugger
      // toast(res.msg, res.check);
      // cancelDelete();
      sessionStorage.clear();
      window.location.reload(true);
      setIsLoading2(false);
    });
  };

  const cancelDelete = () => {
    debugger;
    setRemoveOverlay(false);
    showDeleteIcon(false);
    // setDeleteCheck(false);
    setSelectedItems([]);
    // setGrayscale(false);
    window.location.reload(true);
    // setDeleteCheck(false);
  };
useEffect(()=>{
  hideSearchDiv();
},[])
  useEffect(() => {
    if (searchResults.length > 0) {
      setSearchResultStatus(true);
      console.log(searchResultStatus);
    }
    else{
      setSearchResultStatus(false);
      hideSearchDiv();

    }
  }, [searchResults]);

  useEffect(() => {
    //3
    setIsLoading2(true);
    // setTimeout(() => {

    //     setIsLoading2(false)
    // }, 1000000)

    const sessionData = sessionStorage.getItem("catalogData");

    if (sessionData) {
      setDashboard(JSON.parse(sessionData));
      setIsLoading2(false);
    } else if (!sessionData || sessionData === null) {
      // setIsLoading2(true);
      debugger;
      setIsLoading2(true);
      getDashboard(userEmail)
        .then((res) => {
          //4
          setDashboard(res.data); //5
          sessionStorage.setItem("catalogData", JSON.stringify(res.data));

          // setIsLoading(false);
          // //console.log(dashboard);
          // alert("loading false");
          setIsLoading2(false);
        })

        .catch((err) => console.error("Error fetching books:", err));
    }
  }, []);
  const cancelSynthesis = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
  };
  const hideSearchDiv = ()=>{
    setKeyword("");
    setSearchResults("");
    setSearchResultStatus(false);
  }
  useEffect(() => {
    cancelSynthesis();
  });

  //console.log("dddd", userRole);
  return (
    <div>
      <Navbar />
      <div className="pageContainer">
        {/* <Preloader3 isLoading3={isLoading3} /> */}
        <ToastContainer position="top-right" autoClose={5000} />

        {userRole === "superadmin" && (
          <div>
            {/* <FloatingBtn methods={[handleShow, handleClose, show, handleRemoveOverlay, removeOverlay]} /> */}
            {/* <FloatingBtn methods={[handleShow, removeOverlay]} /> */}
            <FloatingBtn methods={[handleShow, handleRemoveOverlay]} />
            <AddBookComic methods={[handleShow, handleClose, show]} />
          </div>
        )}

        {/* {searchResults.length>0 && ( */}
        {searchResultStatus && (
          <div
            className=""
            style={{
              position: "fixed",
              background: "white",
              height: "90vh",
              width: "100vw",
              top: "6vh",
              left: "0",
              zIndex: "8",
              overflow: "auto",
              display: searchResultStatus ? "block" : "none"
            }}
          >
         
            <div className="pt-5 pb-5 mt-4">
            
              {Array.isArray(searchResults) && searchResults.length > 0 ? (
                <div class="container px-sm-4 book_row mt-2 searchRow">
               <div className="d-flex justify-content-between align-items-center" >
                    <div className="d-flex gap-3">
                      <h5 className="mb-3" style={{textTransform: "capitalize"}}>Search Result</h5></div>
                      {/* <button class="btn btn_viewAll" onClick={hideSearchDiv}>Close Search</button> */}
                  </div>
                <div className="CatalogGrid8">

               


                  {searchResults.map((val, i) => (
                    <div key={i}>
                      <CardBoot
                        urlToImage={val["Cover Page"]}
                        title={val["Book Title"]}
                        isbn={val.isbn}
                        publisher={val.publisher}
                        author={val.Authors[0]["First Name"]}
                        ROverlay={null}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                      />
                    </div>

                  ))}
                </div>
                </div>
              ) : (
                <div class="container px-sm-4 book_row mt-2">
                <p style={{ display: searchResults ? "block" : "none" }}>
                  {searchResults}
                </p>
                </div>
              )}
          
            </div>

            {/* })} */}
          </div>
        )}


        {/* creating dashboard */}
        <div className="mt-4">
          {dashboard.map((val, i) => {
            const [label, values] = Object.entries(val)[0];
            // if (label==="Your Uploaded")
            // //debugger;
            return (
              <div key={i}>
                <div
                  className="container px-sm-4 book_row mt-2"
                  style={{ maxWidth: "1380px" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center"
                    id={label}
                  >
                    <div className="d-flex gap-3">
                      <h5
                        className="mb-0"
                        style={{ textTransform: "capitalize" }}
                      >
                        {label}
                      </h5>

                      {label === "Your Uploaded" && deleteIcon && (
                        <span
                          style={{
                            background: "bisque",
                            padding: "0px 15px",
                            borderRadius: "5px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            beatFade
                            size="1x"
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={sendRemovedList}
                          />
                          &nbsp; / &nbsp;
                          <label
                            onClick={cancelDelete}
                            style={{ fontWeight: "600", cursor: "pointer" }}
                          >
                            {" "}
                            Cancel
                          </label>
                        </span>
                      )}
                    </div>
                    {/* <button className="btn">View All</button> */}
                    <Link
                      to={`/all?cat=${label}`}
                      cat={label}
                      className="btn btn_viewAll"
                    >
                      View All
                    </Link>
                  </div>

                  {/* {searchResults ??  */}
                  {/* <div className='row mobile_flexRow g-1'> */}
                  <div className="CatalogGrid8 g-1">
                    {values.map((val, i) => (
                      <div key={i}>
                        {/* <CardBoot urlToImage={val["Cover Page"]["$binary"].base64} title={val["Book Title"]} isbn={val.isbn} publisher={val.publisher} author={val.Authors[0]["First Name"]} ROverlay={label === "Your Uploaded" ? removeOverlay : null} selectedItems={selectedItems} setSelectedItems={setSelectedItems} /> */}

                        <CardBoot
                          urlToImage={val["Cover Page"] || null}
                          title={val["Book Title"]}
                          isbn={val.isbn}
                          publisher={val.publisher}
                          author={val.Authors[0]["First Name"]}
                          ROverlay={
                            label === "Your Uploaded" ? removeOverlay : null
                          }
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}

          {/* <CatalogMu data={dashboard.data} methods={removeOverlay} /> */}
          {/* <CatalogNr />
                    <CatalogCr />
                    <CatalogA />
                    <CatalogC /> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Catalog;
