// import React, { useContext, useState } from 'react';
import React, { useContext, useState, useEffect, useRef } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  faMagnifyingGlass,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Img from './subComonents/Img'
// import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'

// const iconSearch =  <FontAwesomeIcon icon={faMagnifyingGlass} />;

import Dropdown from "react-bootstrap/Dropdown";
import sendResetLink from "../api/sendResetLink";
import { ToastContainer, toast } from "react-toastify";
import { CheckContext, searchContext, userDetailsContext } from "../App";
import { Modal } from "react-bootstrap";
import LoginFormAdmin from "../pages/LoginFormAdmin";

const Navbar2 = () => {
  const iconSearch = <FontAwesomeIcon icon={faMagnifyingGlass} />;
  const iconPowerOff = <FontAwesomeIcon icon={faPowerOff} />;
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2] =
    useContext(CheckContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const userRole = userDetails?.data?.roles;
  const userEmail = userDetails?.data?.email;

  const [show, setShow] = useState(false);

  // search functionality
  const [keyword, setKeyword, searchResults, setSearchResults] =
    useContext(searchContext);
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef(new AbortController());
  const cooldownTimeoutRef = useRef(null);

  const debounce = (func, delay) => {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };
  const handleSearch = async () => {
    try {
      setLoading(true);
      abortControllerRef.current.abort(); // Abort the previous request
      abortControllerRef.current = new AbortController();

      const response = await fetch(
        "https://comixflip.nexorityinfotech.com:8080/Comix/rest/B/main",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              keyword: keyword,
            },
            method: "searchComic",
          }),
          signal: abortControllerRef.current.signal, // Attach the signal to the request
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch data");
        return;
      }

      const data = await response.json();

      setSearchResults(data.data || []); // Initialize with an empty array if results are undefined
      console.log(data.data);
    } catch (error) {
      if (error.name === "AbortError") {
        // Ignore the abort error
      } else {
        console.error("Error during fetch:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const delayedSearch = debounce(() => {
    handleSearch();
  }, 500); // Adjust the delay based on your preference

  const handleInputChange = (e) => {
    let newKeyword = e.target.value;

    setKeyword(newKeyword);

    // Clear the cooldown timeout and restart it
    clearTimeout(cooldownTimeoutRef.current);
    cooldownTimeoutRef.current = setTimeout(() => {
      delayedSearch();
    }, 1000); // Adjust the cooldown duration based on your preference

    // Check if the input is empty and clear the search results
    if (newKeyword.trim() === "") {
      // debugger;
      // setKeyword('');
      setSearchResults([]);
      console.log("lllll");
      // newKeyword='';
    }
  };
  useEffect(() => {
    // Cleanup function to clear the cooldown timeout
    return () => {
      clearTimeout(cooldownTimeoutRef.current);
    };
  }, [searchResults]);

  // ................
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const logoutSession = (e) => {
    e.preventDefault();
    // //debugger;
    // toast("You have been Logout and will be redirect to Login screen soon...");
    setIsLoading2(true);
    // setTimeout(function () {
    // //debugger
    <Navigate to={navigate("/")} />;
    // }, 2000)
    localStorage.removeItem("User");
    localStorage.removeItem("check");
    sessionStorage.clear();
    setIsLoading2(false);
  };

  const handleSendResetLink = (e) => {
    //debugger;
    e.preventDefault();
    setIsLoading2(true);
    // const emailu=
    sendResetLink(JSON.parse(localStorage.getItem("User")).data.email)
      // sendResetLink("d2dtechnoboom@gmail.com")
      .then(({ ...rest }) => {
        //debugger
        const resData = { ...rest };
        // setIsLoading2(false);
        // if (resData.msg == 0 || resData.msg === undefined) alert("Please enter a valid Email");
        setIsLoading2(false);
        if (resData.msg === undefined)
          toast.error("Please enter a valid Email");
        else toast(resData.msg);

        //console.log(resData.msg)
        // navigate('/');
      })
      .catch((err) => {
        setError(err.message);
        toast.error(err.message);
        setIsLoading2(false);
      });
  };

  const backBtnFunctionality = () => {
    navigate(-1);
    // cancelSynthesis();
  };
  //console.log("NAVV", userRole);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        id="CR_modalAdd"
        size="md"
        style={{ zIndex: "9999" }}
        className="mt-5 mt-sm-0"
      >
        <Modal.Header closeButton onCick={handleClose}>
          {/* <Modal.Title className="mb-3"><h3>Add New Book</h3></Modal.Title> */}
        </Modal.Header>

        <Modal.Body className="py-0">
          <LoginFormAdmin />
        </Modal.Body>
      </Modal>

      <nav className="navbar navbar-expand-lg navbar-light bg_custom p-0 px-3 px-sm-5 shadow">
        {/* <ToastContainer position="top-right" autoClose={5000} /> */}
        <div className="d-flex justify-content-between w-100">
          <div>
            {/* <Link className="navbar-brand" to={navigate("/catalog")}> */}
            {/* <img className="pointer" style={{width:"40px"}} src={require("../assets/icons/icon(12).png")} onClick={() => navigate(-1)} alt="LOGO" title="Back" /> */}
            <img
              className="pointer"
              style={{ width: "40px" }}
              src={require("../assets/icons/icon(12).png")}
              onClick={backBtnFunctionality}
              alt="LOGO"
              title="Back"
            />
            {/* </Link> */}
            <Link className="navbar-brand" to="/catalog/">
              {/* <img className="brandLogo" src={require("../assets/icons/DGP.png")} alt="LOGO" /> */}
              <img
                className="brandLogo"
                src={require("../assets/icons/favicon.ico")}
                alt="LOGO"
              />
            </Link>
          </div>
          {/* <div className="d-flex flex-column justify-content-center">
                    <img className="brandLogo ms-auto" style={{ "width": "30px" }} src={require("../assets/icons/Ellipse7.png")} alt="LOGO" />

                </div> */}
          <div className="d-flex">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* <div className="collapse navbar-collapse" id="navbarSupportedContent" > */}

              <div className="form-inline my-2 my-lg-0 d-flex position-relative">
                <input
                  className="form-control mr-sm-2"
                  placeholder="Search"
                  aria-label="Search"
                  type="search"
                  value={keyword}
                  onChange={handleInputChange}
                />
                {/* <input
        type="text"
        value={keyword}
        onChange={handleInputChange}
        placeholder="Enter keyword"
      /> */}

                <button className="my-sm-0 iconRight_input" type="submit">
                  {iconSearch}
                </button>
              </div>
              {/* <button className=" btn my-sm-0" type="button" onClick={logoutSession} title="Logout">
                            {iconPowerOff}
                        </button> */}
              {/* notification dd */}
              <div className="dropdown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      className="brandLogo ms-auto"
                      style={{ width: "30px" }}
                      src={require("../assets/icons/notificationbing.png")}
                      alt="Notifications"
                      title="Notifications"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div className="d-flex flex-column justify-content-start align-items-start">
                        <ul className="Noti_ULcontainer">
                          <li>1</li>
                          <li>2</li>
                          <li>3</li>
                        </ul>
                      </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item to="/">Reset Password</Dropdown.Item>
                                    <Dropdown.Item to="/" onClick={logoutSession} >Logout</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* profile dd */}
              <div className="dropdown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      className="brandLogo ms-auto"
                      style={{ width: "30px" }}
                      src={require("../assets/icons/Ellipse7.png")}
                      alt="Profile"
                      title="Profile"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {(userRole === "superadmin" || userRole === "admin") && (
                      <Dropdown.Item onClick={handleShow}>Signup</Dropdown.Item>
                    )}
                    {(userRole === "superadmin" || userRole === "admin") && (
                      <Dropdown.Item to="/">Manage Account</Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={handleSendResetLink}>
                      Reset Password
                    </Dropdown.Item>
                    <Dropdown.Item href="/" onClick={logoutSession}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar2;
