import { API_URL } from "./config"

export default (name, email, contact) => {
    // //debugger;
    return fetch(`${API_URL}/Comix/rest/B/main`, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json',
            // "Accept-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "POST",
            // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"

        },
        body: JSON.stringify({

            "data": {
                "name": name,
                "email": email,
                "contact": contact
              
            },
            "method": "userEnquiry"

        })
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Submission failed.. Please contact administrator');

            }
        })
}
