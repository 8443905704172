import { API_URL } from "./config"

export default (fN, email, password, phone, role) => {
    // //debugger;
    return fetch(`${API_URL}/Comix/rest/B/main`, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json',
            // "Accept-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "POST",
            // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"

        },
        body: JSON.stringify({

            "data": {
                "Name": fN,
                // "lastName": lN,
                "email": email,
                "password": password,
                "phone":phone,
                "role":role
            },
            "method": "signUpUser"

        })
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('signup failed.. Please contact administrator');

            }
        })
}
