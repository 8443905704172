// import { faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom'
import BtnGreen from './btnGreen';
import BtnOrange from './btnOrange';

const FloatingBtn = (props) => {

  const [expand, setExpand] = useState(false);
  function expandFloatingDiv() {
    setExpand(!expand);
  }



  return (
    <div className="btn_container p-1">
      {/* <Link className="" to="/"> */}
      <img className="expandBtnImg" onClick={expandFloatingDiv} src={require(`../../assets/icons/icon(${expand ? "13" : "12"}).png`)} alt="LOGO" />
      {/* </Link> */}
      <div className={`d-flex justify-content-between align-items-center gap-2 ${expand ? "" : "d-none"}`}>
        <BtnGreen text={"Add"} click={props.methods[0]} />
        <BtnOrange text={"Remove"} click={props.methods[1]} />
        {/* <Link to="/reader"><BtnOrange text={"Remove"} /></Link>  */}
        {/* <button className="btn px-4 shadow btn_add">Add</button>
        <button className="btn px-4 shadow btn_remove">Remove</button> */}
      </div>


    </div>
  )
}

export default FloatingBtn