import React from 'react'

const Preloader2 = ({ isLoading2 }) => {

    if (!isLoading2) return null
    return (
        <div data-title="dot-pulse">
            <div className="Preloader2_stage">
                <div className="dot-pulse"></div>
            </div>
        </div>
    )
}
export default Preloader2