import React, { useContext, useEffect, useState } from 'react';
import signupRequest from '../api/signupRequest';
import { useNavigate } from 'react-router-dom';
import { CheckContext, userDetailsContext } from '../App';


// import Lottie from "lottie-react";
// import animation1 from "../assets/animations/studentWithRocket.json";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faArrowRightToBracket, faKey, faEye, faEyeSlash, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Preloader from '../components/preloader';
import loginRequest from '../api/loginRequest';
import { ToastContainer, toast } from 'react-toastify';
import Preloader2 from '../components/subComonents/Preloader2';
// import { Modal } from 'bootstrap';


const iconUser = <FontAwesomeIcon icon={faUser} />
const iconPassword = <FontAwesomeIcon icon={faKey} />
const iconShow = <FontAwesomeIcon icon={faEye} />
const iconHide = <FontAwesomeIcon icon={faEyeSlash} />
const iconLogin = <FontAwesomeIcon icon={faArrowRightToBracket} />
const iconPhone = <FontAwesomeIcon icon={faPhone} />
const iconEmail = <FontAwesomeIcon icon={faEnvelope} />
// const svgImgBlob={"../assets/images/Ellipse3.svg"};

export const LoginFormAdmin = (props) => {

    const [activeCheck, setActiveCheck] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [nameSignup, setNameSignup] = useState();
    const [showPassword1Signup, setShowPassword1Signup] = useState(true);
    const [showPassword2Signup, setShowPassword2Signup] = useState(true);
    const [emailSignup, setEmailSignup] = useState("");
    const [contactNoSignup, setContactNoSignup] = useState("");
    const [roleSignup, setRoleSignup] = useState("User");

    const [password1Signup, setPassword1Signup] = useState("");
    const [password2Signup, setPassword2Signup] = useState("");
    const [error, setError] = useState('');
    const [check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2] = useContext(CheckContext);
    const [userDetails, setUserDetails] = useContext(userDetailsContext);
    const navigate = useNavigate();
    // const [userImg, setUserImg] = useState("");
    const [activeForm, setActiveForm] = useState("signup");
    const [signupRes, setSignupRes] = useState({});
    const userRole = userDetails?.data?.roles;
    const userEmail = userDetails?.data?.email;
    // const [isLoading2, setIsLoading2] = useState(false);




    // let emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    let emailRegex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let mobileRegex = new RegExp(/^[7-9][0-9]{9}$/);
    let alphaNumRegex = new RegExp(/^[a-zA-Z]*$/);
    // let passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/);
    let passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[^\w\s]).{1,20}$/);


    // console.log("navvvvbar1", userRole);
    const handleSignup = (e) => {
        //debugger;
        e.preventDefault();
        if ((!nameSignup && !emailSignup && !contactNoSignup && !password1Signup && !password2Signup)) {
            toast.error('Please Fill all fields');
            return false;
        }
        else if (!nameSignup) {
            toast.error('"Name" must have a value.');
            return false;
        }
        else if (nameSignup.length > 20) {
            toast.error('"Name" must be 20 characters long maximum')
            return false;
        }
        // else if (!(alphaNumRegex.test(nameSignup.toLocaleLowerCase()))) {
        //     toast.error('"Name" is not of the expected format')
        //     return false;
        // }
        else if (!emailSignup) {
            toast.error('"Email Address" must have a value')
            return false;
        }
        else if (emailSignup.length > 50) {
            toast.error('"Email Address" must be 50 characters long maximum')
            return false;
        }

        else if (!(emailRegex.test(emailSignup))) {
            toast.error('"Email Address" is not of the expected format.')
            return false;
        }

        // else if (!contactNoSignup) {
        //     toast.error('"Contact Number" must have a value')
        //     return false;
        // }
        else if ((contactNoSignup.length >= 1) && (contactNoSignup.length != 10)) {
            toast.error('"Contact No." must be 10 characters long maximum.')
            return false;
        }
      
        // else if (!(mobileRegex.test(contactNoSignup))) {
        //     toast.error('"Contact No." is not of the expected format')
        //     return false;
        // }

        if (!password1Signup) {
            toast.error('"Password" must have a value');
            return false;
        }
        else if (password1Signup.length > 20) {
            toast.error('"Password" must be 20 characters long maximum');
            return false;
        }
        else if (password1Signup.length < 10) {
            toast.error('"Password" must be minimum 10 characters long');
            return false;
        }
        else if (!(passwordRegex.test(password1Signup))) {
            toast.error('"Password" must have at least one Numeric value, Capital and Small letter and Special character also (e.g., Rohan@12#)')
            return false;
        }
        else if (password1Signup !== password2Signup) {
            toast.error('"Password & Re-Enter Password" values do not match')
            return false;
        }
        else {
            setIsLoading2(true);
            //console.log("laoding true");
            signupRequest(nameSignup, emailSignup, password2Signup, contactNoSignup, roleSignup)
                .then(({ check, ...rest }) => {
                    // //debugger;
                    const restData = { ...rest }
                    setIsLoading2(false);
                    if (check) {
                        toast.success(restData.msg);
                        setNameSignup("");
                        setEmailSignup("");
                        setContactNoSignup("");
                        setPassword1Signup("");
                        setPassword2Signup("");
                    }
                    else if (!check) toast.error(restData.msg);

                    setSignupRes(restData.msg);

                    //console.log(restData.msg);
                    // alert(signupRes);

                }).catch(err => {
                    setError(err.message);
                });
        }
    }

    const handleSignin = (e) => {

        e.preventDefault();
        //debugger
        if (!(emailRegex.test(email))) {
            toast.error("Email Address must have a value. & Password must have a value")
            return false;
        }

        // if (!(emailRegex.test(email))) {
        //     toast.error("Email address must have a valid value")
        //     return false;
        // }
        else if (!password) {
            toast.error("Email Address must have a value. & Password must have a value")
            return false;
        }
        else if (email.length > 50) {
            toast.error('"Email Address" must be 50 characters long maximum')
            return false;
        }
        else if (password.length > 20) {
            toast.error('"Password" must be 20 characters long maximum.')
            return false;
        }
        else {
            //debugger;
            setIsLoading2(true)

            // if (localStorage.getItem("check")) {
            if (!activeCheck) {
                localStorage.removeItem("signedIn");
                localStorage.removeItem("check");
                // alert("token and check removed");
            }
            if (activeCheck) {
                localStorage.setItem("signedIn", "gedgythn43546458&dfsdsfTTTTyu*");
                // alert("keep me signed enabled now and token will be saved");
            }
            e.preventDefault();
            console.log("loading true");
            loginRequest(email, password)
                .then(({ check, ...rest }) => {
                    //debugger
                    setCheck(check);

                    const resData = { ...rest }
                    if (check) {
                        // setUserDetails(resData)
                        // const uDetails= userDetails;


                        // sessionStorage.setItem("UserName", resData.data.Name)
                        sessionStorage.setItem("UserEmail", resData.data.email)
                        // sessionStorage.setItem("UserProfilePic", resData.data.profilePic)
                        // sessionStorage.setItem("UserRole", resData.data.roles[0])

                        // toast.success(resData.msg)
                        localStorage.setItem("check", check)

                        navigate('/catalog')
                        setIsLoading2(false);
                    }
                    else {
                        toast.error(resData.msg)
                        setIsLoading2(false);
                    }
                })
                .catch(err => {
                    setError(err.message);
                    toast.error(error);
                });
        }
        // }

        // else if (localStorage.getItem("check") && localStorage.getItem("signedIn")) {
        //     // e.preventDefault();
        //     // setIsLoading(true);
        //     navigate('/catalog');
        //     // setIsLoading(false);
        // }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handleRoleChange = (e) => {
        setRoleSignup(e.target.value);
        //console.log(roleSignup);
        // e.classList.add(roleSignup);
        // console.log(e.target.value);
    }
    const handleEmailChangeSignup = (e) => {
        setEmailSignup(e.target.value);
    }
    const handleContactNoChangeSignup = (e) => {
        setContactNoSignup(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handlePasswordChange1Signup = (e) => {
        setPassword1Signup(e.target.value);
    }
    const handlePasswordChange2Signup = (e) => {
        setPassword2Signup(e.target.value);
    }

    function showPasswordIcon() {
        setShowPassword(!showPassword);
    }

    function showPasswordIcon1Signup() {
        setShowPassword1Signup(!showPassword1Signup);
    }
    function showPasswordIcon2Signup() {
        setShowPassword2Signup(!showPassword2Signup);
    }
    function isTermsChecked() {
        setActiveCheck(!activeCheck);
        // console.log(activeCheck);
    }


    const handleNameChangeSignup = (e) => {
        setNameSignup(e.target.value);
    }
    function swicthForm() {
        if (activeForm === "signin") setActiveForm("signup");
        else { setActiveForm("signin"); }
    }

    // return isLoading ?
    //     :
    return (





        <div className="col-12 loginFormAdmin">

            <div className="form_container ">
            {/* <img className="brandLogo mb-3" src={require("../assets/icons/DGP.png")} alt="LOGO" /> */}

                <h5 className='mb-3 mb-lg-3'>{activeForm == "signin" ? "Enter Your Login Details" : "Please Signup"}</h5>


                <form onSubmit={activeForm === "signin" ? handleSignin : handleSignup}>

                    {/* signin form */}
                    <div className={`signinForm ${activeForm === "signin" ? 'active' : ''}`}>
                        <div className="mb-3">
                            <label htmlFor="CR_txtEmail" className="form-label fw-bold">Email Address</label>
                            <div className="input-group mb-4">
                                <span className="iconLeft_input">{iconUser} </span>
                                <input type="text" className="form-control rounded" id="CR_txtEmail" onChange={handleEmailChange} value={email} placeholder="Email Address" aria-label="Email Address" aria-describedby="basic-addon1" maxLength="50" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="CR_txtPswd" className="form-label fw-bold">Password</label>
                            <div className="input-group mb-4">
                                <span className="iconLeft_input">{iconPassword} </span>
                                <input type={showPassword ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswd" onChange={handlePasswordChange} value={password} placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" maxLength="20" />
                                <span className="iconRight_input" onClick={showPasswordIcon}> {(showPassword) ? iconHide : iconShow}</span>
                            </div>
                        </div>

                        <div className="mb-3">
                            <div className="form-check form-switch">
                                <input className={`form-check-input ${activeCheck ? "checked" : ""}`} type="checkbox" role="switch" id="CR_checkSignin" style={{ "width": "3rem" }} onClick={isTermsChecked} />
                                <label className="form-check-label ms-3" htmlFor="CR_checkSignin">Keep Me Signed in</label>
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className={`form-check-label text-danger fw-bold border border-danger w-100 p-1 ${error ? "" : "d-none"}`} >{error}</label>
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn_primary btn-lg w-100" ><span className="me-3">{iconLogin} </span> {activeForm == "signin" ? "Login" : "Signup"}</button>
                        </div>
                        <div className="mb-5">
                            <label className="form-check-label ms-3 CR_link" onClick={swicthForm} >Signup</label>
                        </div>

                    </div>

                    {/* signup form */}
                    <div className={`signupForm ${activeForm === "signup" ? 'active' : ''}`}>
                        <div className="mb-3">
                            <label htmlFor="CR_txtNameSignup" className="form-label fw-bold">Name</label>
                            <div className="input-group mb-2">
                                <span className="iconLeft_input">{iconUser} </span>
                                <input type="text" className="form-control rounded" id="CR_txtNameSignup" onChange={handleNameChangeSignup} value={nameSignup} placeholder="Name" aria-label="Name" aria-describedby="basic-addon1" maxLength="20" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="CR_txtEmailSignup" className="form-label fw-bold">Email Address</label>
                            <div className="input-group mb-2">
                                <span className="iconLeft_input">{iconEmail} </span>
                                <input type="text" className="form-control rounded" id="CR_txtEmailSignup" onChange={handleEmailChangeSignup} value={emailSignup} placeholder="Email Adress" aria-label="Email Address" aria-describedby="basic-addon1" maxLength="50" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="CR_txtContactNoSignup" className="form-label fw-bold">Contact No</label>
                            <div className="input-group mb-2">
                                <span className="iconLeft_input">{iconPhone} </span>
                                <input type="text"  pattern="\d*" className="form-control rounded" id="CR_txtContactNoSignup" onChange={handleContactNoChangeSignup} value={contactNoSignup} placeholder="Contact No" aria-label="Contact No" aria-describedby="basic-addon1" maxLength="10" />
                            </div>
                        </div>
                        {
                            userRole === "superadmin" && (
                                <div className="mb-2">
                                    <label htmlFor="CR_txtEmailSignup" className="form-label fw-bold mb-0">Roles</label>
                                    <div className="d-flex justify-content-between">
                                        <div className="input-group d-flex">
                                            <input type="radio" name="roles" id="signup_RdoUser" value="User" onChange={handleRoleChange} className="w-auto me-2" checked />
                                            <label htmlFor="signup_RdoUser">User</label>
                                        </div>
                                        <div className="input-group d-flex">
                                            <input type="radio" name="roles" id="signup_RdoAdmin" value="Admin" onChange={handleRoleChange} className="w-auto me-2" />
                                            <label htmlFor="signup_RdoAdmin">Admin</label>
                                        </div>

                                    </div>
                                </div>
                            )}

                        <div className="mb-3">
                            <label htmlFor="CR_txtPswdSignup" className="form-label fw-bold">Password</label>
                            <div className="input-group mb-2">
                                <span className="iconLeft_input">{iconPassword} </span>
                                <input type={showPassword1Signup ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswd1Signup" onChange={handlePasswordChange1Signup} value={password1Signup} placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" maxLength="20" />
                                <span className="iconRight_input" onClick={showPasswordIcon1Signup}> {(showPassword1Signup) ? iconHide : iconShow}</span>

                            </div>
                            <div className="input-group mb-2">
                                <span className="iconLeft_input">{iconPassword} </span>
                                <input type={showPassword2Signup ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswd2Signup" onChange={handlePasswordChange2Signup} value={password2Signup} placeholder="Re-enter Password" aria-label="Password" aria-describedby="basic-addon1" maxLength="20" />
                                <span className="iconRight_input" onClick={showPasswordIcon2Signup}> {(showPassword2Signup) ? iconHide : iconShow}</span>

                            </div>
                        </div>

                        {/* <div className="mb-5">
                                        <div className="form-check form-switch">
                                            <input className={`form-check-input ${activeCheck ? "checked" : ""}`} type="checkbox" role="switch" id="CR_checkSignin" style={{ "width": "3rem" }} onClick={isTermsChecked} />
                                            <label className="form-check-label ms-3" htmlFor="CR_checkSignin">Keep Me Signed in</label>
                                        </div>
                                    </div> */}
                        <div className="mb-4">
                            <label className={`form-check-label text-danger fw-bold border border-danger w-100 p-1 ${error ? "" : "d-none"}`} >{error}</label>
                        </div>
                        <div className="mb-3">
                            <button type="submit" className="btn btn_primary btn-lg w-100" ><span className="me-3">{iconLogin} </span> {activeForm == "signin" ? "Login" : "Signup"}</button>
                        </div>
                        <div className="mb-0">

                            <label className="form-check-label ms-3 CR_link" onClick={swicthForm}>Signin</label>

                        </div>


                    </div>

                </form>
            </div>
        </div>






    )
}

export default LoginFormAdmin