// import React, { Component } from "react";

// class CanvasAutomate extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       currentFrameIndex: 0,
//       imageUrl: this.props.imgUrl,
//       loadedImage: null,
//       zoomAnimationValue: 1.0,
//       isAnimating: true, // Add a flag to control animation
//     };

//     this.frameCoordinates = this.props.imgCordinates;
//     this.animationDuration = 5000;
//     this.controller = null;
//   }

//   componentDidMount() {
//     this.loadImage();
//     this.initAnimation();
//   }

//   // Function to start animation
//   startAnimation() {
//     this.setState({ isAnimating: true }, this.initAnimation);
//   }

//   // Function to stop animation
//   stopAnimation() {
//     this.setState({ isAnimating: false });
//     clearInterval(this.controller);
//   }

//   // Function to pause animation
//   pauseAnimation() {
//     this.setState({ isAnimating: false });
//     clearInterval(this.controller);
//   }

//   // // Function to resume animation
//   resumeAnimation() {
//     this.setState({ isAnimating: true }, this.initAnimation);
//   }

//   initAnimation() {
//     this.controller = setInterval(() => {
//       const { currentFrameIndex, isAnimating } = this.state;

//       if (isAnimating) {
//         const zoomAnimationValue =
//           this.state.zoomAnimationValue === 1.0 ? 1.5 : 1.0; // Toggle between zoom-in and zoom-out

//         this.setState({
//           currentFrameIndex:
//             (currentFrameIndex + 1) % this.frameCoordinates.length,
//           zoomAnimationValue,
//         });
//       }
//     }, this.animationDuration);
//   }

//   loadImage() {
//     const { imageUrl } = this.state;
//     const image = new Image();
//     image.src = imageUrl;

//     image.onload = () => {
//       this.setState({
//         loadedImage: image,
//       });
//     };
//   }

//   calculateZoomedRectangle() {
//     const { loadedImage, currentFrameIndex, zoomAnimationValue } = this.state;
//     const coordinates = this.frameCoordinates[currentFrameIndex];

//     // Calculate the coordinates for the zoomed rectangle
//     const [x1, y1, x2, y2, x3, y3, x4, y4] = coordinates;

//     // Calculate the width and height of the zoomed rectangle
//     const rectWidth = x2 - x1;
//     const rectHeight = y3 - y1;

//     // Calculate the center point of the zoomed rectangle
//     const centerX = (x1 + x2) / 2;
//     const centerY = (y1 + y3) / 2;

//     // Calculate the left and top positions of the zoomed rectangle
//     const zoomedRectLeft = centerX - (rectWidth * zoomAnimationValue) / 2;
//     const zoomedRectTop = centerY - (rectHeight * zoomAnimationValue) / 2;

//     return {
//       left: zoomedRectLeft,
//       top: zoomedRectTop,
//       width: rectWidth * zoomAnimationValue,
//       height: rectHeight * zoomAnimationValue,
//     };
//   }

//   render() {
//     const { loadedImage, zoomAnimationValue } = this.state;

//     if (!loadedImage) {
//       return <div>Loading image...</div>;
//     }

//     const zoomedRect = this.calculateZoomedRectangle();

//     // Calculate canvas dimensions based on the zoomed rectangle
//     const canvasWidth = zoomedRect.width;
//     const canvasHeight = zoomedRect.height;

//     return (
//       <div>
//         <img src={loadedImage.src} alt="Image" style={{ display: "none" }} />
//         <div
//           style={{
//             position: "absolute",
//             width: "100%",
//             height: "100%",
//             top: "0",
//             left: "0",
//             // border: "6px solid #10ff72",
//             zIndex: "999999",
//             /* max-width: 768px; */
//             display: "flex",
//             margin: "auto",
//             justifyContent: "center",
//           }}
//         >
//           <canvas
//             ref={(canvas) => {
//               if (canvas) {
//                 const ctx = canvas.getContext("2d");
//                 ctx.clearRect(0, 0, canvas.width, canvas.height);

//                 // Draw the image with the zoomed rectangle
//                 ctx.drawImage(
//                   loadedImage,
//                   zoomedRect.left,
//                   zoomedRect.top,
//                   zoomedRect.width,
//                   zoomedRect.height,
//                   0,
//                   0,
//                   canvasWidth,
//                   canvasHeight
//                 );

//                 // Draw the red rectangle at the specified coordinates
//                 ctx.strokeStyle = "#eee";
//                 ctx.lineWidth = 1;

//                 // Draw the rectangle using the provided coordinates
//                 ctx.beginPath();
//                 ctx.moveTo(0, 0);
//                 ctx.lineTo(canvasWidth, 0);
//                 ctx.lineTo(canvasWidth, canvasHeight);
//                 ctx.lineTo(0, canvasHeight);
//                 ctx.closePath();
//                 ctx.stroke();
//               }
//             }}
//             style={{
//               width: "100%",
//               height: "100%",
//               maxWidth: "1140px",
//               maxHeight: "87vh",
//             }}
//             width={canvasWidth}
//             height={canvasHeight}
//           />
//           {/* ... (rest of the component's JSX) */}
//           {/* <button onClick={this.startAnimation.bind(this)}>
//             Start Animation
//           </button>
//           <button onClick={this.stopAnimation.bind(this)}>
//             Stop Animation
//           </button>
//           <button onClick={this.pauseAnimation.bind(this)}>
//             Pause Animation
//           </button>
//           <button onClick={this.resumeAnimation.bind(this)}>
//             Resume Animation
//           </button> */}
//         </div>
//       </div>
//     );
//   }
// }

// export default CanvasAutomate;






import React, { Component } from "react";

class CanvasAutomate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFrameIndex: 0,
      // imageUrl: this.props.imgUrl,
      imageUrl: props.imgUrl,
      isAutoplay: props.isAutoplay,
      loadedImage: null,
      zoomAnimationValue: 1.0,
      isAnimating: true,
      animationCompleted: false, // Track animation completion
    };
    
    // this.frameCoordinates = this.props.imgCordinates;
    this.isAutoplay = props.isAutoPlay;
    this.frameCoordinates = props.imgCordinates;
    this.animationDuration = 8000;
    this.controller = null;
  }

  componentDidMount() {
    this.loadImage();
    this.initAnimation();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imgUrl !== this.props.imgUrl) {
      // If imgUrl prop has changed, update the state and re-initiate animation
      this.setState(
        {
          imageUrl: this.props.imgUrl,
          isAnimating: true,
          animationCompleted: false,
        },
        () => {
          this.loadImage();
          this.initAnimation();
        }
      );
    }
  }

  startAnimation() {
    this.setState({ isAnimating: true, animationCompleted: false }, this.initAnimation);
  }

  stopAnimation() {
    this.setState({ isAnimating: false, animationCompleted: false });
    clearInterval(this.controller);
  }

  pauseAnimation() {
    this.setState({ isAnimating: false });
    clearInterval(this.controller);
  }

  resumeAnimation() {
    this.setState({ isAnimating: true }, this.initAnimation);
  }

  initAnimation() {
    this.controller = setInterval(() => {
      const { currentFrameIndex, isAnimating } = this.state;

      if (isAnimating) {
        const nextFrameIndex = (currentFrameIndex + 1) % this.frameCoordinates.length;

        const zoomAnimationValue =
          this.state.zoomAnimationValue === 1.0 ? 1.5 : 1.0;

        this.setState({
          currentFrameIndex: nextFrameIndex,
          zoomAnimationValue,
          animationCompleted: nextFrameIndex === 0, // Check if all frames have been animated
        });

        if (nextFrameIndex === 0) {
          clearInterval(this.controller); // Stop animation when all frames are completed
          this.props.onAnimationComplete();
        }
      }
    }, this.animationDuration);
  }

  loadImage() {
    const { imageUrl } = this.state;
    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      this.setState({
        loadedImage: image,
      });
    };
  }

  calculateZoomedRectangle() {
    const { loadedImage, currentFrameIndex, zoomAnimationValue } = this.state;
    const coordinates = this.frameCoordinates[currentFrameIndex];

    const [x1, y1, x2, y2, x3, y3, x4, y4] = coordinates;

    const rectWidth = x2 - x1;
    const rectHeight = y3 - y1;

    const centerX = (x1 + x2) / 2;
    const centerY = (y1 + y3) / 2;

    const zoomedRectLeft = centerX - (rectWidth * zoomAnimationValue) / 2;
    const zoomedRectTop = centerY - (rectHeight * zoomAnimationValue) / 2;

    return {
      left: zoomedRectLeft,
      top: zoomedRectTop,
      width: rectWidth * zoomAnimationValue,
      height: rectHeight * zoomAnimationValue,
    };
  }

  render() {
    const { loadedImage, zoomAnimationValue, animationCompleted } = this.state;

    if (!loadedImage) {
      return <div>Loading image...</div>;
    }

    const zoomedRect = this.calculateZoomedRectangle();
    const canvasWidth = zoomedRect.width;
    const canvasHeight = zoomedRect.height;

    return (
      <div>
        <img src={loadedImage.src} alt="Image" style={{ display: "none" }} />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            zIndex: "999999",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            top:0,
            bottom:0
          }}
        >
          <canvas
            ref={(canvas) => {
              if (canvas) {
                const ctx = canvas.getContext("2d");
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                ctx.drawImage(
                  loadedImage,
                  zoomedRect.left,
                  zoomedRect.top,
                  zoomedRect.width,
                  zoomedRect.height,
                  0,
                  0,
                  canvasWidth,
                  canvasHeight
                );

                ctx.strokeStyle = "#eee";
                ctx.lineWidth = 1;

                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineTo(canvasWidth, 0);
                ctx.lineTo(canvasWidth, canvasHeight);
                ctx.lineTo(0, canvasHeight);
                ctx.closePath();
                ctx.stroke();
              }
            }}
            style={{
              width: "100%",
              // height: "100%",
              height: this.props.FullScreen ? "97vh" : "87vh" ,
              maxWidth: "1140px",
              maxHeight: "97vh",
              paddingTop: "10px"
            }}
            width={canvasWidth}
            height={canvasHeight}
          />
        </div>
        {/* {!animationCompleted && (
          <button onClick={this.startAnimation.bind(this)}>
            Start Animation
          </button>
        )} */}
        {/* {animationCompleted && (
          <div>Animation Completed</div>
        )}
        <button onClick={this.stopAnimation.bind(this)}>
          Stop Animation
        </button>
        <button onClick={this.pauseAnimation.bind(this)}>
          Pause Animation
        </button>
        <button onClick={this.resumeAnimation.bind(this)}>
          Resume Animation
        </button> */}
      </div>
    );
  }
}

export default CanvasAutomate;
