import { API_URL } from "./config"

export default async (ISBN) => {
    // //debugger;
    const response = await fetch(`${API_URL}/Comix/rest/B/main`, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json',
        },
        body: JSON.stringify({
            "data": {
                "ISBN": ISBN
            },
            "method": "checkIsbn"
        })
    });
    if (response.ok) {
        return response.json();
    } else {
        throw new Error('Failed to check ISBN! Please contact administrator.');

    }
}
