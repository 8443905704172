import React from 'react'

function Preloader({ isLoading }) {

  if (!isLoading) return null
  return (

    <div className="CR_preloader">
      {/* <img src={require("../assets/animations/run.gif")} alt="LOADING..." /> */}
       {/* <video src="" loop></video> */}

      {/* preloader divyanshu */}

      <div id="preloader_container">
        <div className="preloader_divider" aria-hidden="true"></div>
        <p className="preloader_loading-text" aria-label="Loading">
          <span className="preloader_letter" aria-hidden="true">L</span>
          <span className="preloader_letter" aria-hidden="true">o</span>
          <span className="preloader_letter" aria-hidden="true">a</span>
          <span className="preloader_letter" aria-hidden="true">d</span>
          <span className="preloader_letter" aria-hidden="true">i</span>
          <span className="preloader_letter" aria-hidden="true">n</span>
          <span className="preloader_letter" aria-hidden="true">g</span>
        </p>
      </div>
     {/* preloader divyanshu ends */}


   </div>
  )
}

export default Preloader