import React, { useContext, useEffect, useState } from 'react';
import loginRequest from '../api/loginRequest';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckContext, userDetailsContext } from '../App';

import Lottie from "lottie-react";
import animation1 from "../assets/animations/studentWithRocket.json";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faArrowRightToBracket, faKey, faEye, faEyeSlash, faPhone, faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons'
import Preloader from '../components/preloader';
import userEnquiry from '../api/userEnquiry';
import sendResetLink from '../api/sendResetLink';
import resetPassword from '../api/resetPassword';
import ResetPasswordForm from './resetPasswordForm';
// import { Toasty } from '../components/subComonents/Toast';
import Toastify from '../components/subComonents/Toastify';
// import { ToastContainer, toast } from 'react-toastify';
import { ToastContainer, toast } from 'react-toastify';


const iconUser = <FontAwesomeIcon icon={faUser} />
const iconPassword = <FontAwesomeIcon icon={faKey} />
const iconShow = <FontAwesomeIcon icon={faEye} />
const iconHide = <FontAwesomeIcon icon={faEyeSlash} />
const iconLogin = <FontAwesomeIcon icon={faArrowRightToBracket} />
const iconPhone = <FontAwesomeIcon icon={faPhone} />
const iconEmail = <FontAwesomeIcon icon={faEnvelope} />
const iconCancel = <FontAwesomeIcon icon={faXmark} />
// const svgImgBlob={"../assets/images/Ellipse3.svg"};

export const LoginForm = () => {

    const [activeCheck, setActiveCheck] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [nameSignup, setNameSignup] = useState("");
    const [showPhoneSignup, setshowPhoneSignup] = useState(true);
    const [showpassword2Reset, setShowpassword2Reset] = useState(true);
    const [emailSignup, setEmailSignup] = useState("");
    const [phoneSignup, setphoneSignup] = useState("");
    const [password2Reset, setpassword2Reset] = useState("");
    const [error, setError] = useState();
    const [check, setCheck, isLoading, setIsLoading] = useContext(CheckContext);
    const [userDetails, setUserDetails] = useContext(userDetailsContext);

    const navigate = useNavigate();
    // const [userImg, setUserImg] = useState("");
    const [activeForm, setActiveForm] = useState("signin");
    const [enquiryRes, setEnquiryRes] = useState({});

    // searching paramerters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get('resetuser');

    // regex
    let emailRegex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
    let mobileRegex = new RegExp(/^[7-9][0-9]{9}$/);
    let alphaNumRegex = new RegExp(/^a-z$/);
    // let passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[^\w\s]).{10,20}$/);
    let passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=.*[^\w\s]).{1,20}$/);


    // useeffect
    useEffect(() => {
        //debugger;

        setTimeout(() => {
            setIsLoading(false);
        }, 1500);
        if (searchTerm) {

            setActiveForm("reset");

        }
        else {
            //console.log("welcome guest!");

        }





        if (localStorage.getItem("check") && localStorage.getItem("signedIn") && queryParams.size === 0) {

            setIsLoading(true);
            navigate('/catalog/');


            // setIsLoading(false);
        }

    }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        //debugger
        if (!(emailRegex.test(email))) {
            toast.error("Email Address must have a value. & Password must have a value")
            return false;
        }

        // if (!(emailRegex.test(email))) {
        //     toast.error("Email address must have a valid value")
        //     return false;
        // }
        else if (!password) {
            toast.error("Email Address must have a value. & Password must have a value")
            return false;
        }
        else if (email.length > 50) {
            toast.error('"Email Address" must be 50 characters long maximum')
            return false;
        }
        else if (password.length > 20) {
            toast.error('"Password" must be 20 characters long maximum.')
            return false;
        }
        else {
            //debugger;
            setIsLoading(true)

            // if (localStorage.getItem("check")) {
            if (!activeCheck) {
                localStorage.removeItem("signedIn");
                localStorage.removeItem("check");
                // alert("token and check removed");
            }
            if (activeCheck) {
                localStorage.setItem("signedIn", "gedgythn43546458&dfsdsfTTTTyu*");
                // alert("keep me signed enabled now and token will be saved");
            }
            e.preventDefault();
            //console.log("loading true");

            loginRequest(email.toLowerCase(), password)
                .then(({ check, ...rest }) => {
                    //debugger
                    setCheck(check);

                    const resData = { ...rest }
                    //console.log(resData)
                    setUserDetails(resData)

                    localStorage.setItem("User", JSON.stringify(resData));

                    if (check) {
                        // setUserDetails({resData})
                        // //console.log(userDetails);


                        // sessionStorage.setItem("UserEmail", resData.data.email)
                        // sessionStorage.setItem("UserRole", resData.data.role)

                        // toast.success(resData.msg)
                        localStorage.setItem("check", check)

                        navigate('/catalog')
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        toast.error(resData.msg)
                        return false;
                    }
                })
                .catch(err => {
                    setError(err.message);
                    toast.error(error);
                    setIsLoading(false);
                    return false;

                });
        }

        // }

        // else if (localStorage.getItem("check") && localStorage.getItem("signedIn")) {
        //     // e.preventDefault();
        //     // setIsLoading(true);
        //     navigate('/catalog');
        //     // setIsLoading(false);
        // }
    }

    const handleEnquiry = (e) => {
        //debugger;
        e.preventDefault();
        if (!nameSignup) {
            toast.error('"Name" must have a value.');
            return false;
        }
        else if (nameSignup.length > 20) {
            toast.error('"Name" must be 20 characters long maximum')
            return false;
        }
        // else if (!(alphaNumRegex.test(nameSignup.toLocaleLowerCase() ))) {
        //     toast.error('"Name" is not of the expected format')
        //     return false;
        // }

        else if (!emailSignup) {
            toast.error('"Email Address" must have a value')
            return false;
        }
        else if (emailSignup.length > 50) {
            toast.error('"Email Address" must be 50 characters long maximum')
            return false;
        }

        else if (!(emailRegex.test(emailSignup))) {
            toast.error('"Email Address" is not of the expected format.')
            return false;
        }
        // else if (!phoneSignup) {
        //     toast.error('"Contact Number" must have a value')
        //     return false;
        // }
        else if ((phoneSignup.length >= 1) && (phoneSignup.length != 10)) {
            toast.error('"Contact No." must be 10 characters long maximum.')
            return false;
            
        }
        // else if (!(mobileRegex.test(phoneSignup))) {
        //     toast.error('"Contact No." is not of the expected format')
        //     return false;
        // }
        else {
            setIsLoading(true);
            //console.log("loading true");
            userEnquiry(nameSignup.toLowerCase(), emailSignup, phoneSignup)
                .then(({ check, ...rest }) => {
                    // //debugger;
                    //debugger
                    const restData = { ...rest }
                    setIsLoading(false);

                    // alert(restData.msg);
                    setEnquiryRes(restData);
                    if (!check) toast.error(restData.msg);
                    else if (check) toast.success(restData.msg);
                    // console.log(restData.msg);
                    // alert(signupRes);

                }).catch(err => {
                    setIsLoading(false);
                    setError(error);
                    toast.error(error)
                    return false;
                });
        }
    }
    const handleResetPass = (e) => {
        //debugger;
        e.preventDefault();
        if (!password) {
            toast.error('"Password" must have a value');
            return false;
        }
        else if (password.length > 20) {
            toast.error('"Password" must be 20 characters long maximum');
            return false;
        }
        else if (password.length < 10) {
            toast.error('"Password" must be minimum 10 characters long');
            return false;
        }
        else if (!(passwordRegex.test(password))) {
            toast.error('"Password" must have at least one Numeric value, Capital and Small letter and Special character also (e.g., Rohan@12#)')
            return false;
        }
        else if (password !== password2Reset) {
            toast.error('"Password & Re-Enter Password" values do not match')
            return false;
        }
        else {

            setIsLoading(true);
            //console.log("loading true");
            // ResetPasswordForm(searchTerm, password)
            resetPassword(searchTerm, password)
                .then(({ check, ...rest }) => {
                    // //debugger;
                    const restData = { ...rest }
                    setIsLoading(false);

                    check ? toast.success(restData.msg) : toast.error(restData.msg)

                    // setEnquiryRes(restData.msg);
                    //console.log(restData.msg);
                    // alert(signupRes);

                }).catch(err => {
                    setError(err.message);
                    toast.error(error);
                    setIsLoading(false);
                });
        }
    }

    const handleSendResetLink = (e) => {
        //debugger;

        e.preventDefault();
        if (!email) {
            toast.error('"Email Address" must have a value')
            return false;
        }
        else if (email.length > 50) {
            toast.error('"Email Address" must be 50 characters long maximum')
            return false;
        }

        else if (!(emailRegex.test(email))) {
            toast.error('"Email Address" is not of the expected format.')
            return false;
        }

        else {
            setIsLoading(true);
            // setIsLoading(true);

            sendResetLink(email)
                .then(({ ...rest }) => {
                    //debugger
                    const resData = { ...rest }
                    setIsLoading(false);
                    if (resData.msg == 0 || resData.msg === undefined) {
                        toast.error("undefined error occured, contact administrator");

                        // alert("Please enter a valid Email");
                    }

                    else {
                        if (resData.check) {
                            // alert(resData.msg);
                            toast.success(resData.msg);
                        }
                        else if (!resData.check) {
                            // alert(resData.msg);
                            toast.error(resData.msg);
                        }
                    }

                    // console.log(resData.msg)
                })
                .catch(err => {
                    setError(err.message);
                    toast.error(error)
                })
        }
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handleEmailChangeSignup = (e) => {
        setEmailSignup(e.target.value);
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    const handlePhoneChangeSignup = (e) => {
        setphoneSignup(e.target.value);
    }
    const handlePasswordChange2Signup = (e) => {
        setpassword2Reset(e.target.value);
    }

    function showPasswordIcon() {
        setShowPassword(!showPassword);
    }

    function showPhoneIconSignup() {
        setshowPhoneSignup(!showPhoneSignup);
    }
    function showPasswordIcon2Signup() {
        setShowpassword2Reset(!showpassword2Reset);
    }
    function isTermsChecked() {
        setActiveCheck(!activeCheck);

        // console.log(activeCheck);
    }


    const handleNameChangeSignup = (e) => {
        setNameSignup(e.target.value);
    }
    function swicthForm() {
        if (activeForm === "signin") setActiveForm("signup");
        // else if (activeForm === "signin") setActiveForm("reset");
        else { setActiveForm("signin"); }
    }

    function resetForm() {

        if (activeForm === "signin") setActiveForm("reset");
        // else setActiveForm("reset");
    }
    const handleSubmit = (e) => {

        //debugger;
        if (activeForm === "signin") {
            //     //debugger
            //     if (!email) {
            //         toast.error("Email Address must have a value.")
            //         return false;
            //     }
            //     else if (!password) {
            //         toast.error("Password must have a value")
            //         return false;
            //     }
            // else {
            return handleLogin(e);
            // }
        }
        else if (activeForm === "signup") { return handleEnquiry(e); }
        else if (activeForm === "reset") { return handleResetPass(e); }


    }


    // return isLoading ?
    //     
    return (

        <div className="login_container">
            {/* <Toastify type={toastType} msg={toast} /> */}
            <ToastContainer position={"top-right"} autoClose={5000} />
            <img className="blob" src={require("../assets/images/Ellipse3.png")} alt="alt text" />

            <div className="container h-100 position-relative">
                <div className="row d-flex justify-content-center align-items-center loginFormRow">
                    <div className={`col-12 col-xl-8 col-sm-5  ${activeForm === "signin" ? 'd-block' : 'd-none'}`}>
                        <div className="w-75 animation_container">
                            <Lottie animationData={animation1} loop={true} />

                            {/* <Toastify style={"success"} text={"error"} /> */}
                        </div>

                    </div>
                    <div className="col-10 col-xl-4 col-md-5 col-sm-7 my-sm-3">
                        <div className="form_container p-4 p-md-5">


                            <form onSubmit={handleSubmit}>

                                {/* signin form */}
                                <div className={`signinForm ${activeForm === "signin" ? 'active' : ''}`}>
                                {/* <img className="brandLogo mb-3"  src={require("../assets/icons/DGP.png")} alt="LOGO" /> */}

                                    <h5 className='mb-3 mb-lg-4'>Enter Your Login Details</h5>
                                    <div className="mb-3">
                                        <label htmlFor="CR_txtEmail" className="form-label fw-bold">Email Address</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconUser} </span>
                                            <input type="email" className="form-control rounded" id="CR_txtEmail" onChange={handleEmailChange} value={email} placeholder="Email Address" aria-label="Email Address" aria-describedby="basic-addon1" maxLength="50" />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="CR_txtPswd" className="form-label fw-bold">Password</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconPassword} </span>
                                            <input type={showPassword ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswd" onChange={handlePasswordChange} value={password} placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" maxLength="20" />
                                            <span className="iconRight_input" onClick={showPasswordIcon}> {(showPassword) ? iconHide : iconShow}</span>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <div className="form-check form-switch">
                                            <input className={`form-check-input ${activeCheck ? "checked" : ""}`} type="checkbox" role="switch" id="CR_checkSignin" onClick={isTermsChecked} />
                                            <label className="form-check-label ms-3" htmlFor="CR_checkSignin">Keep Me Signed in</label>
                                        </div>
                                    </div>

                                    {/* <div className="mb-4">
                                        <label className="form-check-label text-danger fw-bold border border-danger w-100 p-1" >Error Message will be here. Its visible for demo purpose only. It will be hidden.</label>
                                    </div> */}

                                    <div className="mb-4">
                                        <button type="submit" className="btn btn_primary btn-lg w-100" style={{ "marginTop": "6px" }} ><span className="me-3">{iconLogin} </span> {activeForm == "signin" ? "Login" : "Signup"}</button>
                                    </div>
                                    <div className="mb-4 d-flex justify-content-between">
                                        <label className="form-check-label ms-3 CR_link" onClick={swicthForm} >Signup</label>
                                        <label onClick={handleSendResetLink} className="form-check-label ms-3 CR_link" >Reset Password</label>
                                    </div>
                                </div>

                                {/* enquiry form */}
                                <div className={`signupForm ${activeForm === "signup" ? 'active' : ''}`}>
                                {/* <img className="brandLogo mb-3"  src={require("../assets/icons/DGP.png")} alt="LOGO" /> */}
                                    <h5 className='mb-3 mb-lg-4'>Please Fill Enquiry Form</h5>
                                    <div className="mb-3">
                                        <label htmlFor="CR_txtNameSignup" className="form-label fw-bold">Name</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconUser} </span>
                                            <input type="text" className="form-control rounded" id="CR_txtNameSignup" onChange={handleNameChangeSignup} value={nameSignup} placeholder="Name" aria-label="Name" aria-describedby="basic-addon1" maxLength="20" />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="CR_txtEmailSignup" className="form-label fw-bold">Email Address</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconEmail} </span>
                                            <input type="email" className="form-control rounded" id="CR_txtEmailSignup" onChange={handleEmailChangeSignup} value={emailSignup} placeholder="Email Adress" aria-label="Email Address" aria-describedby="basic-addon1" maxLength="50" />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="CR_txtPhoneSignup" className="form-label fw-bold">Contact no</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconPhone} </span>
                                            <input type="text" pattern="\d*" maxLength="10" className="form-control fs-6 rounded" id="CR_txtPhoneSignup" onChange={handlePhoneChangeSignup} value={phoneSignup} placeholder="Contact no" aria-label="Phone no" aria-describedby="basic-addon1"  />

                                        </div>
                                        {/* <div className="input-group mb-2">
                                            <span className="iconLeft_input">{iconPassword} </span>
                                            <input type={showpassword2Reset ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswd2Signup" onChange={handlePasswordChange2Signup} value={password2Reset} placeholder="Re-enter Password" aria-label="Password" aria-describedby="basic-addon1" />
                                            <span className="iconRight_input" onClick={showPasswordIcon2Signup}> {(showpassword2Reset) ? iconHide : iconShow}</span>

                                        </div> */}
                                    </div>
                                    <div className="mb-4">
                                        <label className={`form-check-label text-danger fw-bold border border-danger w-100 p-1 ${error ? "" : "d-none"}`} >{error}</label>
                                    </div>
                                    <div className="row my-4">
                                        <div className="col-12">
                                            <button type="submit" className="btn btn_primary btn-lg w-100" ><span className="me-3">{iconLogin} </span> {activeForm == "signin" ? "Login" : "Submit"}</button>
                                        </div>
                                        {/* <div className="col-6">
                                            <button type="button" className="btn btn_primary btn-lg w-100" onClick={swicthForm} ><span className="me-3">{iconCancel} </span>Cancel</button>
                                        </div> */}
                                    </div>

                                    <div className="mb-0">
                                        <label className="form-check-label ms-3 CR_link" onClick={swicthForm} >Signin</label>
                                    </div>
                                    {/* <div className="mb-5">
                                        <div className="form-check form-switch">
                                            <input className={`form-check-input ${activeCheck ? "checked" : ""}`} type="checkbox" role="switch" id="CR_checkSignin" style={{ "width": "3rem" }} onClick={isTermsChecked} />
                                            <label className="form-check-label ms-3" htmlFor="CR_checkSignin">Keep Me Signed in</label>
                                        </div>
                                    </div> */}
                                </div>

                                {/* reset password form */}
                                <div className={`resetForm ${activeForm === "reset" ? 'active' : ''}`} style={{ "marginBottom": "45px" }} >
                                    <h5 className='mb-3 mb-lg-4'>Reset Your Password</h5>
                                    <div className="mb-3">
                                        <label htmlFor="CR_txtPswdReset" className="form-label fw-bold">Password</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconPassword} </span>
                                            <input type={showPassword ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswdReset" onChange={handlePasswordChange} value={password} placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" maxLength="20" />
                                            <span className="iconRight_input" onClick={showPasswordIcon}> {(showPassword) ? iconHide : iconShow}</span>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="CR_txtEmail" className="form-label fw-bold">Re-enter Password</label>
                                        <div className="input-group">
                                            <span className="iconLeft_input">{iconPassword} </span>
                                            <input type={showpassword2Reset ? "password" : "text"} className="form-control fs-6 rounded" id="CR_txtPswd2Signup" onChange={handlePasswordChange2Signup} value={password2Reset} placeholder="Re-enter Password" aria-label="Password" aria-describedby="basic-addon1" maxLength="20" />
                                            <span className="iconRight_input" onClick={showPasswordIcon2Signup}> {(showpassword2Reset) ? iconHide : iconShow}</span>

                                        </div>
                                    </div>



                                    {/* <div className="mb-3">
                                        <div className="form-check form-switch">
                                            <input className={`form-check-input ${activeCheck ? "checked" : ""}`} type="checkbox" role="switch" id="CR_checkSignin" style={{ "width": "3rem" }} onClick={isTermsChecked} />
                                            <label className="form-check-label ms-3" htmlFor="CR_checkSignin">Keep Me Signed in</label>
                                        </div>
                                    </div> */}

                                    <div className="mb-4">
                                        <label className={`form-check-label text-danger fw-bold border border-danger w-100 p-1 ${error ? "" : "d-none"}`} >{error}</label>
                                    </div>

                                    <div className="row my-4">
                                        <div className="col-12">
                                            <button type="submit" className="btn btn_primary btn-lg w-100" style={{ "marginTop": "34px" }}><span className="me-3">{iconLogin} </span>Submit</button>
                                        </div>
                                        {/* <div className="col-6">
                                            <button type="button" className="btn btn_primary btn-lg w-100" onClick={swicthForm} ><span className="me-3">{iconCancel} </span>Cancel</button>
                                        </div> */}
                                    </div>

                                    <div className="mb-3 d-flex justify-content-between">
                                        <label className="form-check-label ms-3 CR_link" onClick={swicthForm} >Signin</label>
                                        {/* <label className="form-check-label ms-3" style={{ "color": "rgb(0,131,247)", 'text-decoration': 'underline', 'fontWeight': '600', 'cursor': 'pointer' }}>Reset Password</label> */}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    )
}

export default LoginForm