import { API_URL } from "./config";

export default async (isbn, email) => {
  debugger;
  const response = await fetch(`${API_URL}/Comix/rest/B/main`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      "data": {
        "ISBN": isbn,
        "user":email
      },
      "method": "removeBookmarkPage"
    }),
  });
  if (response) {
    return response.json();
  } else {
    throw new Error(
      "Failed to adding book in favourites... Please contact administrator"
    );
  }
};
