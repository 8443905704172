import React from 'react'

function BtnOrange(props) {
    return (
        <button className="btn px-4 shadow btn_remove" onClick={props.click ? props.click : null}>{props.text}</button>
    )
}
// BtnOrange.defaultProps = {
//     text: "Button",
//     click: "null",
//   }
export default BtnOrange