import React from 'react'

function Footer() {
  return (

        <div className="row d-flex justify-content-center align-items-center MR_footer">
            <div className="col-12 text-center p-1">
                <small className="m-0">Copyright © 2023 Nexority Infotech. All rights reserved(v1.0)</small>
                {/* <small className="m-0">Copyright © 2023 Digital Pathway. All rights reserved(v1.1)</small> */}
           
            </div>
        </div>
 
  )
}

export default Footer