
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import Lottie from "lottie-react";
import animation1 from "../assets/animations/error-404.json";
const title = "Error 404!";
const desc = "Oops! The Page You Are Looking For Could Not Be Found";
const btnText = "Go Back To Home";




const ErrorPage = () => {
    return (
        <div className="four-zero-section padding-tb section-bg">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-sm-6 col-12">
                        <div className="four-zero-content">
                            <Link to="/">
                                {/* <img src="assets/images/logo/01.png" alt="CodexCoder" /> */}
                            </Link>
                            <h2 className="title">{title}</h2>
                            <p>{desc}</p>
                            <Link to="/" className="btn "><span>{btnText} <i className="icofont-external-link"></i></span></Link>
                        </div>
                    </div>
                   
                    <div className="col-lg-5 col-sm-5 col-12">
                        <div className="d-flex m-auto" style={{"width":"100%"}}>
                            <Lottie animationData={animation1} loop={true} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default ErrorPage;