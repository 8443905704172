import { API_URL } from "./config"

export default async (APIMethod, pageNo, pageSize) => {
    // //debugger;
    const response = await fetch(`${API_URL}/Comix/rest/B/main`, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json',
        },
        body: JSON.stringify({
            "data": {
                "pagination": {
                    "pageNumber": pageNo, // 0,
                    "pageSize": pageSize //20
                }
            },
            "method": APIMethod
        })
    });
    if (response.ok) {
        return response.json();
    } else {
        throw new Error('Failed to fetch Books... Please contact administrator');

    }
}
