import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

function AlertD (props) {
  const [show, setShow] = useState(true);
  const DURATION = 5000;

  // automatic alert off after few time

  // if (show) {
  //   setTimeout(() => {
  //     setShow(false);
  //   }, DURATION)
  // }


  //  function HideAlert() {
  //   setShow(false);
  //  }



  return (
    <Alert className='px-5' variant={props.variant} onClose={() => setShow(false)} dismissible>
      {props.text} <Alert.Link href="#">{props.link}</Alert.Link>
    </Alert>
  );
}

export default AlertD;