import React from 'react';

const StarRating = ({ rating, onStarClick }) => {
  const stars = Array(5).fill(0); // Create an array of 5 elements

  return (
    <div className={'starRatingContainer'}>
      {stars.map((_, index) => (
        <span
          key={index}
          onClick={() => onStarClick(index + 1)}
          style={{ color: index < rating ? 'gold' : 'gray', cursor: 'pointer' }}
        >
          &#9733;
        </span>
      ))}
    </div>
  );
};

export default StarRating;
