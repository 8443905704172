import { API_URL } from "../api/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext, useRef } from "react";
import Footer from "../components/footer";
import Navbar2 from "../components/navbar2";
import ImageSlider from "../components/imageSlider";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
// import { faEllipsiVertical } from '@fortawesome/sharp-regular-svg-icons'
import { useLocation } from "react-router-dom";
import StarRating from "../components/starRating";
import CanvasAutomate from "../components/CanvasAutomate";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import FloatingBtn from "../components/subComonents/floating-btn";
import BtnOrange from "../components/subComonents/btnOrange";
import BtnGreen from "../components/subComonents/btnGreen";
import SetStarRating from "../api/SetStarRating";
import { ToastContainer, toast } from "react-toastify";
import { CheckContext } from "../App";
import getThumbnail from "../api/getThumbnail";
import GetConversation from "../api/GetConversation";
// import downloadCBZ from "../api/favAdd";
import favAdd from "../api/favAdd";
import favRemove from "../api/favRemove";
import bookmarkAdd from "../api/bookmarkAdd";
import bookmarkRemove from "../api/bookmarkRemove";

const mobileFilter = <FontAwesomeIcon icon={faEllipsisVertical} />;

export const Reader = (props) => {
  let counter = useRef(0);
  const canvasAutomateRef = useRef(null);

  const initVoice = () => {
    //debugger;

    // const voices = window.speechSynthesis.getVoices();

    // //console.log("voice setted");
    // const selectedVoiceName = localStorage.getItem("voice") || voices[0].name;
    // const selectedVoice = voices.find(
    //   (voice) => voice.name === selectedVoiceName
    // );

    // return selectedVoice;

    const voices = window.speechSynthesis.getVoices();
    setVoices(voices);
    
    const selectedVoiceName = localStorage.getItem("voice") || voices[0].name;
    const selectedVoice = voices.find(
      (voice) => voice.name === selectedVoiceName
    );

    if (selectedVoice) {
      localStorage.setItem("voice", selectedVoice.name);
      setSelectedVoice(selectedVoice);
    }
    return selectedVoice;
  };

  const [check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2] =
    useContext(CheckContext);

  const [FullScreen, setFullScreen] = useState(false);
  const [View2x, setView2x] = useState(false);

  const [DarkMode, setDarkMode] = useState(false);
  const [Like, SetLike] = useState(false);
  const [Sound, SetSound] = useState(false);
  const [MobileFilter, ViewMobileFilter] = useState(false);
  const location = useLocation();
  // let subtitleTextHitesh;
  // const location = useLocation();
  const { isbnp, publisherp } = location.state || {};
  const userEmail = JSON.parse(localStorage.getItem("User")).data.email || null; //muchhar kate how
  const [pageNo, setpageNo] = useState("");
  const [rating, setRating] = useState(0);
  const [activeThumbnail, setActiveThumbnail] = useState(false);
  const [thumbnail, setThumbnail] = useState([]);
  const [showRating, setShowRating] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [zoomin, setZoomin] = useState(false);
  const [zoomout, setZoomout] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [voices, setVoices] = useState([]);
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");
  const [selectedVoice, setSelectedVoice] = useState();
  const [speechData, setSpeechData] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false); // State to track play/pause
  const [imgUrl, setImgUrl] = useState();

  const [cordinates, setCordinates] = useState([]);
  const [speech, setSpeech] = useState([]);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  let [currentSpeechCords, setCurrentSpeechCords] = useState(0);
  const [isFav, setIsFav] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isBookmarkedPageNo, setisBookmarkedPageNo] = useState("");
  const [defaultValueVoice, setDefaultValueVoice] = useState("");
  const [defaultValueLang, setDefaultValueLang] = useState("");
  const [subtitleText, setSubtitleText] = useState("");
  const [voiceContent, setVoiceContent] = useState();
  const [triggerRight, setTriggerRight] = useState();
  const [voiceText, setVoiceText] = useState(null);
  // const SERVERFILEPATH = "https://comixflipbucket.s3.amazonaws.com/cbzfiles";
  const SERVERFILEPATH = "https://newcomixflipbucket.s3.amazonaws.com/cbzfiles";
  const thumbnailContainerRef = useRef(null);
  const mediaContainerRef = useRef(null);
  const [imageNames, setImageNames] = useState([]);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  // const [targetImageName, setTargetImageName] = useState(isBookmarkedPageNo);

  const [indexImg, setIndexImg] = useState(null);
  let isAutoPlayVariable = false;
  let arrImg = [];
  let arrImgName = [];
  let arr = [];
  let bookmarkStatus, bookmarkedPage, favRes;
  let bookmarkedPageIndex;
  let pageJump = 1;
  let currentSubtitle;
  let currentSpeechData=[];
  let currentSpeech=[];
  let langColl = [
    { name: "Afrikaans", val: "af" },
    { name: "Albanian", val: "sq" },
    { name: "Amharic", val: "am" },
    { name: "Arabic", val: "ar" },
    { name: "Armenian", val: "hy" },
    { name: "Azerbaijani", val: "az" },
    { name: "Bengali", val: "bn" },
    { name: "Bosnian", val: "bs" },
    { name: "Bulgarian", val: "bg" },
    { name: "Catalan", val: "ca" },
    { name: "Chinese (Simplified)", val: "zh" },
    { name: "Chinese (Traditional)", val: "zh-TW" },
    { name: "Croatian", val: "hr" },
    { name: "Czech", val: "cs" },
    { name: "Danish", val: "da" },
    { name: "Dari", val: "fa-AF" },
    { name: "Dutch", val: "nl" },
    { name: "English", val: "en" },
    { name: "Estonian", val: "et" },
    { name: "Farsi (Persian)	", val: "fa" },
    { name: "Filipino, Tagalog	", val: "tl" },
    { name: "Finnish", val: "fi" },
    { name: "French", val: "fr" },
    { name: "French (Canada)", val: "fr-CA" },
    { name: "Georgian", val: "ka" },
    { name: "German", val: "de" },
    { name: "Greek", val: "el" },
    { name: "Gujarati", val: "gu" },
    { name: "Haitian Creole", val: "ht" },
    { name: "Hausa", val: "ha" },
    { name: "Hebrew", val: "he" },
    { name: "Hindi", val: "hi" },
    { name: "Hungarian", val: "hu" },
    { name: "Icelandic", val: "is" },
    { name: "Indonesian", val: "id" },
    { name: "Irish", val: "ga" },
    { name: "Italian", val: "it" },
    { name: "Japanese", val: "ja" },
    { name: "Kannada", val: "kn" },
    { name: "Kazakh", val: "kk" },
    { name: "Korean", val: "ko" },
    { name: "Latvian", val: "lv" },
    { name: "Lithuanian", val: "lt" },
    { name: "Macedonian", val: "mk" },
    { name: "Malay", val: "ms" },
    { name: "Malayalam", val: "ml" },
    { name: "Maltese", val: "mt" },
    { name: "Marathi", val: "mr" },
    { name: "Mongolian", val: "mn" },
    { name: "Norwegian (Bokmål)", val: "no" },
    { name: "Pashto", val: "ps" },
    { name: "Polish", val: "pl" },
    { name: "Portuguese (Brazil)", val: "pt" },
    { name: "Portuguese (Portugal)", val: "pt-PT" },
    { name: "Punjabi", val: "pa" },
    { name: "Romanian", val: "ro" },
    { name: "Russian", val: "ru" },
    { name: "Serbian", val: "sr" },
    { name: "Sinhala", val: "si" },
    { name: "Slovak", val: "sk" },
    { name: "Slovenian", val: "sl" },
    { name: "Somali", val: "so" },
    { name: "Spanish", val: "es" },
    { name: "Spanish (Mexico)", val: "es-MX" },
    { name: "Swahili", val: "sw" },
    { name: "Swedish", val: "sv" },
    { name: "Tamil", val: "ta" },
    { name: "Telugu", val: "te" },
    { name: "Thai", val: "th" },
    { name: "Turkish", val: "tr" },
    { name: "Ukrainian", val: "uk" },
    { name: "Urdu", val: "ur" },
    { name: "Uzbek", val: "uz" },
    { name: "Vietnamese", val: "vi" },
    { name: "Welsh", val: "cy" },
  ];

  // zooming acc to cordinates
  const [zoomed, setZoomed] = useState(false);
  const [panned, setPanned] = useState(false);

  // };
  View2x ? (pageJump = 2) : (pageJump = 1);
  let pageLabel = currentIndex + pageJump;

  useEffect(() => {
    setTimeout(() => {
      // getConversationComic();
      initVoice();
    }, 3000);

    // useEffect(()=>{
    //   setTimeout(()=>{

    //   })
    // })

    // // setTimeout(() => {
    // const soundStatus = sessionStorage.getItem("playMode");
    // if (soundStatus === "true") {
    //   // toast(sessionStorage.getItem("playMode")+"lll");
    //   const g = speechData;

    //   //console.log(g,"ggggg", arrImgName[bookmarkedPage]);
    //   // //console.log(g, "speechdata")
    //   soundOn();
    // }
    // // }, 500);
  }, [currentSpeechData]);

  useEffect(() => {
    setFullScreen(false);
    sessionStorage.setItem("autoplayStatus", "false");
    const handleKeyUp = (e) => {
      if (e.key === "ArrowLeft") {
        // setTriggerRight(true);
        goToPrev();
      } else if (e.key === "ArrowRight") {
        ////debugger

        goToNext();
        // window.location.reload(true);
      } else if (e.key === "f") {
        // go_full_screen();
        EnableFullScreen(e);
        // alert("fullscreen");
      } else if (e.key === "Escape") {
        ////debugger;
        e.preventDefault();
        setFullScreen(false);
        exitFullScreen(e);
      }
      // else if (e.key === "Space") {
      //   goToNext();
      // }
    };
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);


useEffect(()=>{
  if (isAutoPlayVariable) {
    //console.log("autplay settimeout3");
    // setTimeout(function () {
    autoPlay();
    // }, 3000);
  }
},[])

  useEffect(() => {
    // //debugger;

    cancelSynthesis();
    // bringFirstslideData();
    // //debugger;

    // else if (!Sound) {
    //   SetSound();
    // }

   
    if (!arrImg.length) {
      // //debugger;
      getThumbnailData();
    }
    // Scroll the thumbnail container to the clicked thumbnail
    const thumbnailElement =
      thumbnailContainerRef.current.children[currentIndex];
    if (thumbnailElement) {
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      const thumbnailElementAll = thumbnailContainerRef.current.children;

      Array.from(thumbnailElementAll).forEach((k) => {
        k.classList.remove("active");
      });
      //debugger;
      // if(setIsBookmarked){
      //   alert("ddd");
      // }
      // //console.log(setisBookmarkedPageNo,"lll222");
      // //console.log(setIsBookmarked,"lll333");
      thumbnailElement.classList.add("active");
      thumbnailElement.classList.add("highLightBookmarked");
    }
    // thumbnailElement.classList.add("active");
    const onVoicesChanged = () => {
      // //debugger;
      const voices = window.speechSynthesis.getVoices();
      setVoices(voices);

      const selectedVoiceName = localStorage.getItem("voice") || voices[0].name;
      const selectedVoice = voices.find(
        (voice) => voice.name === selectedVoiceName
      );

      if (selectedVoice) {
        localStorage.setItem("voice", selectedVoice.name);
        setSelectedVoice(selectedVoice);
      }
    };

    ////debugger;
    const voices = window.speechSynthesis.getVoices();
    setVoices(voices);
    window.speechSynthesis.addEventListener("voiceschanged", onVoicesChanged);
  }, [currentIndex]);

  // appending images to seperate array
  thumbnail.forEach((k, i) => {
    arrImg.push(SERVERFILEPATH + `/` + isbnp + `/` + k.fileName);
    arrImgName.push(k.fileName);
  });
  // const playSoundIfSoundIsOn=()=>{
  //   // setTimeout(() => {
  //     const soundStatus = sessionStorage.getItem("playMode");
  //     if (soundStatus === "true") {
  //       // toast(sessionStorage.getItem("playMode")+"lll");
  //       const g = speechData;

  //       //console.log(g, "ggggg", arrImgName[currentIndex]);
  //       // //console.log(g, "speechdata")
  //       soundOn();
  //     }
  //     // }, 500);

  //       }

  const scrollToBookmarkedPage = () => {
    //debugger;

    toast.success(
      `Your Last Bookmarked Page no. ${
        arrImgName.findIndex(findBookIndex) + 1
      } opened.`
    );
    // setCurrentIndex(arrImgName.findIndex(findBookIndex) + 1);currentIndex
    const CI = arrImgName.findIndex(findBookIndex);
    setCurrentIndex(CI);

    //console.log(currentIndex, "CI");
    //console.log(CI, "ppppp");
    // if page is bookmarked and page is scrolled to bookmarked page then it goes for conversation
    //console.log(thumbnailContainerRef.current.children[CI + 1], "LLLLL");
    // bring the thumbnail data
    const thumbnailElement =
      thumbnailContainerRef.current.children[
        arrImgName.findIndex(findBookIndex) + 1
      ];
    //console.log(
    //   thumbnailContainerRef.current.children[
    //     arrImgName.findIndex(findBookIndex) + 1
    //   ],
    //   "LLLLL"
    // );
    // const thumbnailElementFileName =
    //   thumbnailContainerRef.current.children[
    //     arrImgName.findIndex(findBookIndex) + 1
    //   ].childNodes[0].getAttribute("name");

    const thumbnailElementAll = thumbnailContainerRef.current.children;
    if (thumbnailElement) {
      Array.from(thumbnailElementAll).forEach((k) => {
        k.classList.remove("active");
      });

      setTimeout(function () {
        thumbnailElement.classList.add("active");

        //console.log(thumbnailElement, "HItesh");
      }, 5000);
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }

    // getConversationComic();
    // getConversationComic(bookmarkedPage);
    // getConversationComic(thumbnailElementFileName);
    // bringFirstslideData();

    return true;
  };

  function findBookIndex(f) {
    // //debugger;
    // return f == isBookmarkedPageNo;
    // //console.log(f == bookmarkedPage, "mmmm");
    return f == bookmarkedPage;
  }

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);

    // Scroll the thumbnail container to the clicked thumbnail
    const thumbnailElementAll = thumbnailContainerRef.current.children;
    const thumbnailElement = thumbnailContainerRef.current.children[index];
    const thumbnailElementName =
      thumbnailContainerRef.current.children[index].childNodes[0].getAttribute(
        "name"
      );
    getConversationComic(thumbnailElementName);

    if (thumbnailElement) {
      Array.from(thumbnailElementAll).forEach((k) => {
        k.classList.remove("active");
      });

      thumbnailElement.classList.add("active");
      //console.log(thumbnailElement, "thumbnail element ");
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    //getConversationComic();
  };

  const handleZoomin = (event) => {
    setZoomin(true);
    const { clientX, clientY } = event;
    const { left, top, width, height } = event.target.getBoundingClientRect();
    const offsetX = (clientX - left) / width;
    const offsetY = (clientY - top) / height;
    setZoomLevel(zoomLevel + 0.1); // You can adjust the zoom level as needed
    event.target.style.transformOrigin = `${offsetX * 50}% ${offsetY * 100}%`;
  };
  const handleZoomout = (event) => {
    setZoomin(false);

    FullScreen ? setZoomLevel(1.1) : setZoomLevel(1); // You can adjust the zoom level as needed
  };
  const resetZoom = () => {
    FullScreen ? setZoomLevel(1.1) : setZoomLevel(1);
  };

  const handleStarClick = (newRating) => {
    setRating(newRating);
  };

  const getConversationComic = async (PageName) => {
    //debugger;
    //console.log("IN GETCONVERSATION1 FUNCTION");
    GetConversation(isbnp, PageName, localStorage.getItem("lang") || lang)
      .then((res) => {
        //console.log("IN GETCONVERSATION RESPONSE FUNCTION", res, PageName);
        debugger;
        let response = res;
        if (response.Check) {
          //debugger;
          if (response.data["Text Areas"]) {
            const dd = response.data["Text Areas"];
            const fmPoints = response.data["framePoints"];
            const FinalFmPoints = [];
            if (fmPoints) {
              for (const pointsStr of fmPoints) {
                const points = pointsStr
                  .split(" ")
                  .map((point) => point.split(",").map(Number));
                const flattenedPoints = points.reduce(
                  (acc, point) => acc.concat(point),
                  []
                );
                FinalFmPoints.push(flattenedPoints);
              }
            }

            // //console.log("FinalPoints", FinalFmPoints);

            // //debugger;
            setpageNo(arrImgName[currentIndex + 1]);

            // setSpeechData(dd);
            if(dd){
              dd.forEach((k)=>{
                currentSpeechData.push(k);
                
              })
            };

            counter.current = 0;
console.log( dd,"speechdataandspeech")
// speechData.forEach((k, i) => {
  currentSpeechData.forEach((k, i) => {
  // //debugger;
  setCordinates(FinalFmPoints);
  console.log(FinalFmPoints, "ppp");
  // setSpeech(k.Content);
  currentSpeech.push(k.Content);
  setIsLoading2(false);
  // console.log(speech, speechData, dd,"speechdataandspeech222")
            });
            initVoice();

            // playSoundIfSoundIsOn();
            const soundStatus = sessionStorage.getItem("playMode");
            if (soundStatus === "true") {
              soundOn();
              return "1";
            }
            // const autoplayStatus = sessionStorage.getItem("autoplayStatus");
            // if (autoplayStatus === "true") {
            //   toast("Slide Changed");

            //   if (isAutoPlay) {
            //     debugger;
            //     setImgUrl(arrImg[currentIndex]);
            //     console.log(arrImg[currentIndex]);
            //     // startCanvasAnimation();
            //     startCanvasAnimation();
            //   }
            // }

            // setTimeout(() => {
            //   if (sessionStorage.getItem("playMode")) {
            //     toast(sessionStorage.getItem("playMode"));
            //     soundOn();
            //   }
            // }, 2000);
          }
        } else {
          //debugger;
          if (Sound || isAutoPlayVariable) {
            toast("Error: Page Details not found, Going for the next Page");
            setpageNo(arrImgName[currentIndex + 1]);
            goToNext();
          } else if (Sound) {
            toast(
              "Error: Page Details not found, Please Navigate to prev/next Manually"
            );
          }

          setIsLoading2(false);
        }

        setIsLoading2(false);
      })
      .catch((err) => {
        //debugger;
        setIsLoading2(false);
        toast("err2");
        if (Sound || isAutoPlayVariable) {
          // alert("");
          toast(
            "ErrorCatched: Page Details not found, Going for the next Page"
          );
          setpageNo(arrImgName[currentIndex + 1]);
          goToNext();
        } else {
          toast(
            "Error: Page Details not found, Please Navigate to prev/next Manually"
          );
          // setpageNo(arrImgName[currentIndex + 1]);
          // goToNext();
        }

        console.error(err, "Please contact Administrator!");
      });
  };

  // const findBookmarkPage = (page) => {
  //   return page == isBookmarkedPageNo;
  // };

  const getThumbnailData = () => {
    setIsLoading2(true);
    getThumbnail(userEmail, isbnp)
      .then((res) => {
        // //debugger;
        setpageNo("");
        setIsLoading2(false);
        arr = res.data;
        setThumbnail(arr);

        if (arr) {
          // appending images to seperate array
          arr.forEach((k, i) => {
            arrImg.push(SERVERFILEPATH + `/` + isbnp + `/` + k.fileName);
            arrImgName.push(k.fileName);
            // setImageNames(imageNames.push(k.fileName));
            setImageNames((prevImageNames) => [...prevImageNames, k.fileName]);
          });
          // //debugger;
          bookmarkStatus = res.bookmark;

          if (bookmarkStatus) {
            //debugger;

            bookmarkedPage = res.bookmarkedPage[0];
            setIsBookmarked(bookmarkStatus);
            setisBookmarkedPageNo(bookmarkedPage);
            // setCurrentIndex(bookmarkedPage);
            setpageNo(bookmarkedPage);
            //console.log("BOOKMARKEDPAGEEE", bookmarkedPage);
            mapBookmarkedConversation();
            setCurrentIndex(bookmarkedPageIndex);
            console.log(currentIndex, bookmarkedPageIndex ,"cI and bi");
            getConversationComic(bookmarkedPage).then(function(){

            
            //console.log("BOOKMARKEDPAGE INDEX", bookmarkedPageIndex);
            //console.log(currentIndex, "currentindex pageno");
            scrollToBookmarkedPage();

            //console.log(currentIndex, "currentInde");
            //console.log("not consoling here");
            //debugger;

            // Scroll the thumbnail container to the clicked thumbnail
            const BookmarkedElement =
              thumbnailContainerRef.current.children[bookmarkedPageIndex];
            if (BookmarkedElement) {
              BookmarkedElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
              BookmarkedElement.classList.Add("hitesh");
              //  const thumbnailElementAll = thumbnailContainerRef.current.children;
            }
          });
            //console.log(arrImgName[currentIndex], "tttt pageno");
            //console.log(currentIndex, "currentindex pageno");
          } else {
            //debugger;
            setpageNo(arrImgName[currentIndex]);
            getConversationComic(arrImgName[currentIndex]);

            //console.log(pageNo, "yr2");
          }

          favRes = res.fav;
          setIsFav(favRes);
          if (favRes) {
            SetLike(true);
          } else {
            SetLike(false);
          }
        }
      })
      .catch((err) =>
        toast.error(err, "Something went wrong. Please contact Administrator!")
      );
  };

  const mapBookmarkedConversation = () => {
    bookmarkedPageIndex = arrImgName.findIndex(findBookIndex);
    //console.log(bookmarkedPageIndex, "BookmarkedPageIndex");
    // comment due to bug 20240117
    //debugger;
    // getConversationComic(arrImgName[bookmarkedPage]);

    // getConversationComic(bookmarkedPageIndex);
    return bookmarkedPageIndex;
  };

  function activateThumbnail(e) {
    setCurrentIndex(Number(e.target.title));

    e.target.classList.add("active");
  }
  function go_full_screen() {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  const exitFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  function EnableFullScreen(e) {
    ////debugger;
    setFullScreen(!FullScreen);
    if (FullScreen) {
      exitFullScreen();
    } else {
      go_full_screen();
    }
    return false;
    e.preventDefault();
  }
  const EnableDarkMode = () => {
    setDarkMode(!DarkMode);
  };
  const addToFav = () => {
    //debugger;
    // setIsLoading2(true);
    favAdd(isbnp, userEmail).then((res) => {
      if (res.check) {
        // setIsLoading2(false);
        toast.success("Book is added in favourite list");
        // cancelDelete();
        setTimeout(() => {
          // localStorage.clear();
          // window.location.reload(true);
          //  isSaved(true);
          SetLike(true);
        });
      } else {
        // setIsLoading2(false);
        toast.error("Unable to save Book");
        SetLike(false);
      }
    });
  };

  const removeFromFav = () => {
    favRemove(isbnp, userEmail).then((res) => {
      if (res.check) {
        toast.success("Book is removed from favourite list");
        setTimeout(() => {
          // localStorage.clear();

          setIsFav(false);
          SetLike(false);
        }, 2000);
      } else {
        toast.error("Unable to remove Book from favourites");
        SetLike(true);
      }
    });
  };

  const handleFavClick = () => {
    if (Like) removeFromFav();
    else if (!Like) addToFav();
  };
  const addBookmark = () => {
    //debugger;
    // setIsLoading2(true);
    bookmarkAdd(isbnp, userEmail, pageNo).then((res) => {
      if (res.check) {
        //debugger;
        // setIsLoading2(false);
        toast.success(isBookmarkedPageNo + "Page Bookmarked!");
        setTimeout(() => {
          // localStorage.clear();
          setIsBookmarked(true);
          // //console.log(arrImgName[currentIndex]);
        }, 50);
      } else {
        //debugger;
        // setIsLoading2(false);
        toast.error("Unable to Bookmark the page");
        setIsBookmarked(false);
      }
    });
  };

  const removeBookmark = () => {
    // setIsLoading2(true);
    bookmarkRemove(isbnp, userEmail).then((res) => {
      if (res.check) {
        setTimeout(() => {
          toast.success("Bookmark Removed");

          bookmarkStatus = "";
          bookmarkedPage = "";
          setisBookmarkedPageNo("");
          setIsBookmarked(false);

          // setIsLoading2(false);
        });
      }
    });
  };

  const handleBookmark = () => {
    if (isBookmarked) removeBookmark();
    else if (!isBookmarked) addBookmark();
  };

  const EnableView2x = () => {
    setView2x(!View2x);
  };
  const ToggleMobileFilter = () => {
    ViewMobileFilter(!MobileFilter);
  };
  const submitRatings = () => {
    setIsLoading2(true);

    SetStarRating(rating, isbnp, userEmail)
      .then(() => {
        toast.success("Rating submitted successfully!");
        setIsLoading2(false);
        setRating(0);
        handleCloseRating();
      })
      .catch((err) => toast.error("Error submitting profile:", err));
  };

  const downloadCbzReq = () => {
    // ////debugger;

    toast("File Downloading started!");
  };
  const handleCloseRating = () => {
    setShowRating(false);
    setRating(0);
    // empty star array on closing
  };
  const handleShowRating = () => {
    setShowRating(true);
  };
  const handleCloseLanguage = () => {
    // if (bookmarkStatus) {
    //   mapBookmarkedConversation();
    //   cancelSynthesis();
    // }
    // else {
    //   toast.success("Language Updated!")
    //   getConversationComic(arrImgName[currentIndex]);
    //   cancelSynthesis();
    // }

    initVoice();
    getConversationComic(arrImgName[currentIndex]);
    toast.success("Language Updated!");
    cancelSynthesis();
    setShowLanguage(false);
    setRating(0);
  };
  const handleShowLanguage = () => {
    setShowLanguage(true);
  };
  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? arrImg.length - 1 : prevIndex - pageJump
    );

    const thumbnailElement =
      thumbnailContainerRef.current.children[currentIndex - 1];
    const thumbnailElementFileName =
      thumbnailContainerRef.current.children[
        currentIndex - 1
      ].childNodes[0].getAttribute("name");

    const thumbnailElementAll = thumbnailContainerRef.current.children;
    if (thumbnailElement) {
      Array.from(thumbnailElementAll).forEach((k) => {
        k.classList.remove("active");
      });
      thumbnailElement.classList.add("active");
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }

    getConversationComic(thumbnailElementFileName);
    //console.log(thumbnailElementFileName, "hhhhh");

    if (pageLabel > arrImg.length) {
      setCurrentIndex(0);
      pageLabel = 0;
    }
  };

  const bringFirstslideData = () => {
    //debugger;
    setCurrentIndex((prevIndex) =>
      prevIndex === arrImg.length - 1 ? 0 : pageJump
    );

    const thumbnailElement =
      thumbnailContainerRef.current.children[currentIndex];
    const thumbnailElementFileName =
      thumbnailContainerRef.current.children[
        currentIndex
      ].childNodes[0].getAttribute("name");

    getConversationComic(thumbnailElementFileName);

    const thumbnailElementAll = thumbnailContainerRef.current.children;
    if (thumbnailElement) {
      Array.from(thumbnailElementAll).forEach((k) => {
        k.classList.remove("active");
      });
      thumbnailElement.classList.add("active");
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }

    if (pageLabel > arrImg.length) {
      setCurrentIndex(0);
      pageLabel = 0;
    }
  };

  const goToNext = () => {
    debugger;
   
    setCurrentIndex((prevIndex) =>
      prevIndex === arrImg.length - 1 ? 0 : prevIndex + pageJump
    );

    const thumbnailElement =
      thumbnailContainerRef.current.children[currentIndex + 1];
    const thumbnailElementFileName =
      thumbnailContainerRef.current.children[
        currentIndex + 1
      ].childNodes[0].getAttribute("name");

    getConversationComic(thumbnailElementFileName).then((data) => {
      try {
        debugger;
        if ((data == "1")) {
          const autoplayStatus = sessionStorage.getItem("autoplayStatus");
          if (autoplayStatus === "true" && isAutoPlay) {
              debugger;
              toast("Slide Changed");
              setImgUrl(arrImg[currentIndex]);
              // startCanvasAnimation();
              startCanvasAnimation();

          }
        }
      } catch (e) {
        console.log("errorCatched=>", e);
      }
    });

    const thumbnailElementAll = thumbnailContainerRef.current.children;
    if (thumbnailElement) {
      Array.from(thumbnailElementAll).forEach((k) => {
        k.classList.remove("active");
      });
      thumbnailElement.classList.add("active");
      thumbnailElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }

    if (pageLabel > arrImg.length) {
      setCurrentIndex(0);
      pageLabel = 0;
    }
  };

  const autoPlay = () => {
    debugger;
    setFullScreen(true);
    // EnableFullScreen();
    setIsAutoPlay(true);
    sessionStorage.setItem("autoplayStatus", "true");
    isAutoPlayVariable = true;
    let i;
    setImgUrl(arrImg[currentIndex]);
    // setCordinates()
    if (currentSpeechData) {
      // pausing autoplay temporarily for demo
      //debugger;
      // soundOn();
    }

    if (!currentSpeechData.length) {
      toast("no speech context found");
      goToNext();
    }
    // else if (speechData.length) {
    //   soundOn();
    // }
  };

  let isPaused = false; // Track the paused state
  const pauseSpeech = () => {
    window.speechSynthesis.pause();
    isPaused = true;
    SetSound(false);
  };

  // Function to resume speech
  const resumeSpeech = () => {
    window.speechSynthesis.resume();
    isPaused = false;
    SetSound(true);
  };
  // const autoPlay2 = () => {
  //   //debugger;

  //   // goToNext
  //   setIsAutoPlay(true);
  //   if (Sound || !isPaused) {
  //     window.speechSynthesis.resume();
  //   }
  //   let j, i;
  //   let arrImgLength = arrImg.length;
  //   let speechDataLength = speechData.length;
  //   // LEAVING HERE
  //   if (speechData) {
  //   for (j = 0; j < arrImgLength; j++) {
  //       for (i = 0; i < speechDataLength; i++) {
  //         handleNextSpeechCords(i);
  //       }
  //       sessionStorage.setItem("playMode", true);
  //       if (isAutoPlay) {
  //         // alert("inside autoplay2");
  //         goToNext();
  //       }
  //     }

  //   }

  //   if (!speechSynthesis.speaking) {
  //     soundOn();
  //   }
  // };

  // const autoPlay3 = async () => {
  //   //debugger;
  //   setIsAutoPlay(true);

  //   for (let j = 0; j < arrImg.length; j++) {
  //     const speechDataLength = speechData.length;

  //     for (let i = 0; i <= speechDataLength; i++) {
  //       //console.log("first",i, speechDataLength, arrImg.length)
  //     //   await handleNextSpeechCords(i);
  //     //   // await soundOn();
  //     }
  //     //console.log("yyyy")
  //     sessionStorage.setItem("playMode", true);
  //     goToNext();

  //     // if (isAutoPlay) {
  //       // goToNext();
  //     // }
  //   }

  //   if (!speechSynthesis.speaking) {
  //     soundOn();
  //   }
  // };

  const soundOn = () => {
    debugger;
    //console.log("IN SOUND FUNCTION");
    setIsPlaying(true);
    if (Sound && isPaused) {
      resumeSpeech();
      sessionStorage.setItem("playMode", true);
    }
    // else if (Sound && !isPaused) {
    //   pauseSpeech();
    //   sessionStorage.setItem("playMode", false);
    // }
     else {
      if (currentSpeechData.length) {
        counter.current = 0;
        let i;
        for (i = 0; i < currentSpeechData.length; i++) {
          debugger;
          //console.log(speechData.length, "spdata inside sound");
          handleNextSpeechCords(i);

          sessionStorage.setItem("playMode", true);
        }
      } else {
        SetSound(true);
        sessionStorage.setItem("playMode", true);
        toast.error(
          "No Page Data is available! Please Navigate to PREV/NEXT Slide"
        );
      }
      // if (isAutoPlayVariable) {
      //   //console.log("inside soundON, found autoplay ON so going to NEXT");
      //   goToNext();
      //   // soundOn();
      // }
    }
  };

  let voiceConte;

  const handleNextSpeechCords = (i) => {
    debugger;
    if (currentSpeechCords <= currentSpeechData.length) {
      setCurrentSpeechCords(i + 1);
      voiceConte = currentSpeechData[i].Content;
      if (currentSpeechData[i].Content) {
        debugger;
        setVoiceContent(currentSpeechData[i].Content);
        SetSound(true);
        // adding below if condition temporary to stop voice during autoplay 20240322
        // if (!isAutoPlayVariable) {
        handleSpeak(currentSpeechData[i].Content);
        // .then((data) => {
        //   console.log(data, "dataaa", speechData.length, currentSpeechCords);
        // });
        // return "1";
        // }

        // if (isAutoPlay) {
        //   // alert("inside handleNextSpeechCords");
        //   goToNext();
        //   soundOn();
        // }
        // if (isAutoPlayVariable) {
        //   console.log("going for next in handlenext method")
        //     goToNext();
        //     soundOn();
        // }
      }
    } else {
      setCurrentSpeechCords(0);
      console.log("speechCOrdinates reseted");
      return "-1";
    }
  };

  const handlePrevSpeechCords = () => {
    if (currentSpeechCords > 0) {
      setCurrentSpeechCords(currentSpeechCords - 1);
    } else {
      setCurrentSpeechCords(currentSpeechData.length - 1);
    }
  };

  // speech synthesis
  const handleVoiceChange = (event) => {
    const voiceName = event.target.value;
    const voice = voices.find((v) => v.name === voiceName);
    setSelectedVoice(voice);
    localStorage.setItem("voice", voiceName);
  };

  const handleLangChange = (ev) => {
    setLang(ev.target.value);
    let selectedLang = ev.target.value;
    localStorage.setItem("lang", selectedLang);
  };

  const handleVoiceTextChange = (event) => {
    setVoiceText(event.target.value);
  };

  const handleSpeak = (voiceC) => {
    debugger;

    let utterance;

    if (voiceC && lang && selectedVoice) {
      currentSubtitle = voiceC;
      // console.log(voiceC.length, voiceC)
      utterance = new SpeechSynthesisUtterance(voiceC);
      if (utterance) {
        try {
          utterance.voice = selectedVoice;
          utterance.pitch = 1;

          localStorage.setItem("subtitleText", voiceC);

          window.speechSynthesis.speak(utterance);

          setSubtitleText(voiceContent);
          utterance.onstart = () => {
            setSubtitleText(currentSpeechData[counter.current].Content);
            counter.current++;
            SetSound(true);
            setIsPlaying(true);
          };

          utterance.onend = () => {
            debugger;
            SetSound(false);
            setIsPlaying(false);
            setSubtitleText("");
            // console.log("voice end", voiceC)
            // return "1";
          };
        } catch (err) {
          toast.error("Invalid voice or language... Please select valid pair.");
          console.error("Invalid userInput please contact administrator", err);
          // return "-1";
        }
      }
      return true;
    } else {
      toast.error("Please select the valid speech and language set");
    }
  };

  const handleAnimationComplete = () => {
    debugger;
    setAnimationCompleted(true);
    goToNext();
  };
  const pauseCanvasAnimation = () => {
    // //debugger;

    if (canvasAutomateRef.current) {
      canvasAutomateRef.current.pauseAnimation();
      // pauseSpeech();
      setIsAutoPlay(false);
      setFullScreen(false);
      isAutoPlayVariable = false;
      setAnimationCompleted(false);
    }
  };

  const startCanvasAnimation = () => {
    // //debugger;
    if (canvasAutomateRef.current) {
      setAnimationCompleted(false);
      canvasAutomateRef.current.startAnimation();
      // soundOn();
      setIsAutoPlay(true);
      isAutoPlayVariable = true;
    }
    else{
      setAnimationCompleted(true);
      setIsAutoPlay(false);
      isAutoPlayVariable = false;
      toast(".")

    }
  };
  const cancelSynthesis = () => {
    debugger;
    SetSound(false);
    //debugger;
    const synth = window.speechSynthesis;
    synth.cancel();
    if (sessionStorage.getItem("playMode")) {
     isPaused = false;
     SetSound(true);
    } else {
     isPaused = false;
      SetSound(false);
    }

    isPaused = false;
   
    //   SetSound(false);
  };

  const findImageIndex = (imageArray, targetImageName) => {
    try {
      const index = imageArray.indexOf(targetImageName);
      return index;
    } catch (error) {
      console.error(`${targetImageName} not found in the array.`);
      return null;
    }
  };

  return (
    <div>
      {!FullScreen && <Navbar2 />}
      <ToastContainer position="top-right" autoClose={5000} />
      {/* modal ratings */}
      <Modal
        show={showRating}
        onHide={handleCloseRating}
        id="CR_modalAdd"
        size="md"
        style={{ zIndex: "9999" }}
        className="mt-5 mt-sm-0"
      >
        <Modal.Header closeButton onClick={handleCloseRating}>
          <Modal.Title className="mb-3">
            <h3>Ratings</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <StarRating rating={rating} onStarClick={handleStarClick} />
          <div className="d-flex justify-content-center mb-4 gap-3">
            <BtnGreen text={"Submit"} click={submitRatings} />
            <BtnOrange text={"Cancel"} click={handleCloseRating} />
          </div>
        </Modal.Body>
      </Modal>

      {/* modal voice languages */}
      <Modal
        show={showLanguage}
        onHide={handleCloseLanguage}
        id="CR_modalLanguage"
        size="md"
        style={{ zIndex: "9999" }}
        className="mt-5 mt-sm-0"
      >
        <Modal.Header closeButton onClick={handleCloseLanguage}>
          <Modal.Title className="mb-0">
            <h3>Select Language</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div className="row p-3">
            {/* <div className='bordered border-primary col-12 col-sm-6 text-center'>

                    </div> */}
            <label>Select Voice</label>
            <select
              onChange={handleVoiceChange}
              // defaultValue={localStorage.getItem("voice") || voices[0]}
              defaultValue={localStorage.getItem("voice")}
            >
              {voices.map((voice) => (
                <option key={voice.name} value={voice.name}>
                  {voice.name}
                </option>
              ))}
            </select>

            <label style={{ marginTop: "20px" }}>Select Language</label>
            <select
              onChange={handleLangChange}
              defaultValue={localStorage.getItem("lang") || "en"}
              // defaultValue={setDefaultValueLang}
            >
              {/* <select> */}
              {langColl.map((k) => (
                <option key={k.name} value={k.val}>
                  {k.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="d-flex justify-content-center mb-4 gap-3">
                        <BtnGreen text={"Submit"} click={submitRatings} />
                        <BtnOrange text={"Cancel"} click={handleCloseRating} />
                    </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn px-4 shadow btn_remove"
            onClick={handleCloseLanguage}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className={`pageContainer ${DarkMode ? "darkTheme" : ""}`}
        style={{
          overflow: "hidden",
          minHeight: FullScreen ? "100vh" : "inherit",
          maxHeight: FullScreen ? "100vh" : "inherit",
        }}
      >
        {!View2x && (
          <div
            className={`pageNoLabelLeft ${DarkMode ? "darkFilterInvert" : ""}`}
          >
            <p>{pageLabel}</p>
          </div>
        )}
        {View2x && (
          <div>
            <div
              className={`pageNoLabelLeft ${
                DarkMode ? "darkFilterInvert" : ""
              }`}
            >
              <p>{pageLabel - 1}</p>
            </div>
            {pageLabel < arrImg.length && (
              <div
                className={`pageNoLabelRight ${
                  DarkMode ? "darkFilterInvert" : ""
                }`}
              >
                <p>{pageLabel}</p>
              </div>
            )}
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center">
          <span className="moreBtn" onClick={ToggleMobileFilter}>
            {mobileFilter}
          </span>
          <div
            className={`List_Container ${MobileFilter ? "activeMob" : ""} ${
              FullScreen ? "withFullScreenMode" : ""
            }`}
          >
            <span className="closeBtn" onClick={ToggleMobileFilter}>
              &times;
            </span>
            <ul className="text-center reader_options">
              {/* <li>
                <img
                  src={require("../assets/icons/icon(19).png")}
                  alt="Upload"
                  title="Upload"
                />
              </li> */}
              <li>
                <img
                  src={require(`../assets/icons/icon(${
                    FullScreen ? 31 : 27
                  }).png`)}
                  onClick={EnableFullScreen}
                  alt="Full screen"
                  title="Full screen"
                  loading="lazy"
                />
              </li>

              {!View2x && (
                <li>
                  <img
                    src={require(`../assets/icons/icon(${
                      zoomin ? 37 : 21
                    }).png`)}
                    alt="Zoom in"
                    onClick={handleZoomin}
                    title="Zoom in"
                    loading="lazy"
                  />
                </li>
              )}
              {!View2x && (
                <li>
                  <img
                    src={require("../assets/icons/icon(20).png")}
                    alt="Zoom out"
                    onClick={handleZoomout}
                    title="Zoom out"
                    loading="lazy"
                  />
                </li>
              )}
              <li>
                <img
                  src={require(`../assets/icons/icon(${View2x ? 32 : 28}).png`)}
                  onClick={EnableView2x}
                  alt="1 Page 2 Page"
                  title="1 Page 2 Page"
                  loading="lazy"
                />
              </li>
              {!View2x && (
                <li>
                  <img
                    src={require("../assets/icons/icon(24).png")}
                    onClick={handleShowLanguage}
                    alt="Change language"
                    title="Change language"
                  />
                </li>
              )}
              {!View2x && (
                <li>
                  <img
                    src={require(`../assets/icons/icon(${
                      Sound ? 35 : 36
                    }).png`)}
                    alt="Audio"
                    title="Text to Speech"
                    loading="lazy"
                    onClick={soundOn}
                  />
                </li>
              )}

              {!View2x && (
                <li>
                  <img
                    src={require(`../assets/icons/icon(${
                      // isAutoPlay ? 39 : 38
                      isAutoPlayVariable ? 39 : 38
                    }).png`)}
                    alt="Play"
                    title="Play"
                    loading="lazy"
                    onClick={isAutoPlay ? pauseCanvasAnimation : autoPlay}
                    // onClick={
                    //   isAutoPlayVariable ? pauseCanvasAnimation : autoPlay
                    // }
                  />
                </li>
              )}

              <li>
                <img
                  src={require(`../assets/icons/icon(${Like ? 29 : 18}).png`)}
                  onClick={handleFavClick}
                  alt="Add to favorites"
                  title="Add to favorites"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={require(`../assets/icons/icon${
                    isBookmarkedPageNo == arrImgName[currentIndex]
                      ? "(17)c"
                      : "(17)"
                  }.png`)}
                  alt="Bookmark"
                  loading="lazy"
                  title={
                    isBookmarkedPageNo
                      ? `Remove Bookmark  =>  ${isBookmarkedPageNo} `
                      : "Bookmark"
                  }
                  onClick={handleBookmark}
                />
              </li>
              <li>
                <img
                  src={require("../assets/icons/icon(22).png")}
                  alt="Share"
                  title="Share"
                  loading="lazy"
                  onClick={handleNextSpeechCords}
                />
              </li>
              <li>
                <a
                  onClick={downloadCbzReq}
                  href={API_URL + `/Comix/rest/download/downloadCBZ/${isbnp}`}
                  download={`${isbnp}`}
                >
                  <img
                    src={require("../assets/icons/icon(19).png")}
                    alt="Download"
                    loading="lazy"
                    title="Download"
                  />
                </a>
              </li>
              <li>
                <img
                  src={require("../assets/icons/icon(16).png")}
                  onClick={handleShowRating}
                  alt="Review"
                  title="Review"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={require(`../assets/icons/icon(${
                    DarkMode ? 30 : 15
                  }).png`)}
                  onClick={EnableDarkMode}
                  alt="Dark Mode"
                  title="Dark Mode"
                  loading="lazy"
                />
              </li>
              <li>
                <img
                  src={require("../assets/icons/icon(11).png")}
                  alt="Feedback"
                  title="Feedback"
                  onClick={cancelSynthesis}
                  loading="lazy"
                  // onClick={resumeCanvasAnimation}
                />
              </li>
            </ul>
          </div>

          <div
            ref={mediaContainerRef}
            className={`mediaContainer customScrollbar me-auto ${
              FullScreen ? "fullScreen" : ""
            } ${DarkMode ? "darkTheme" : ""}`}
            style={{
              overflow: zoomin ? "auto" : "hidden",
              height: zoomin ? "89vh" : "auto",
              minHeight: FullScreen ? "100vh" : "inherit",
              zIndex: FullScreen ? "1001" : "10",
            }}
          >
            {/* exit full screen button */}
            {/* <span onClick={EnableFullScreen}>
                            <img className={`closeBtn ${DarkMode ? 'filterInvert' : ''}`} src={require("../assets/icons/arrowleft2.png")} alt="" />
                        </span> */}

            <button
              className="navBtn navBtnLeft"
              style={{ width: View2x ? "22%" : "35%" }}
              onClick={goToPrev}
            >
              {/* <img src={require("../assets/icons/arrowleft2.png")} alt="previous" /> */}
              &nbsp;
            </button>

            {/* <img src={arrImg[currentIndex]} alt="" style={{ transform: `scale(${zoomLevel})  translateX(${FullScreen ? "-20px" : "0"})`, cursor: zoomin ? "-webkit-zoom-in" : "inherit", zIndex: "10" }} onClick={zoomin ? handleZoomin : undefined} /> */}
            {!isAutoPlay && (
            <img
              id="b"
              src={arrImg[currentIndex]}
              alt=""
              loading="lazy"
              style={{
                zoom: FullScreen ? zoomLevel + 0.14 : zoomLevel,

                // zoom: isPlaying
                //   ? FullScreen
                //     ? zoomLevel + 0.14
                //     : 2.6
                //   : zoomLevel,
                transform: `translateX(${FullScreen ? "-20px" : "0"}) scale(${
                  isPlaying ? 1 : "initial"
                })`,
                transformOrigin: isPlaying ? "1671px 873px" : "initial",
                cursor: zoomin ? "zoom-in" : "inherit",
                zIndex: "10",
              }}
              // {{
              //   setpageNo(arrImgName[currentIndex])
              // }}
              onClick={zoomin ? handleZoomin : undefined}
              name={arrImgName[currentIndex]}
            />
            )}
            {View2x && (
              // <img src={arrImg[currentIndex + 1]} style={{ transform: `scale(${zoomLevel}) translateX(${FullScreen ? "20px" : "0"})`, cursor: zoomin ? "-webkit-zoom-in" : "inherit", zIndex: "10", transformOrigin: zoomin ? "" : "0" }} alt="" onClick={zoomin ? handleZoomin : zoomout} />
              <img
                src={arrImg[currentIndex + 1]}
                loading="lazy"
                style={{
                  zoom: FullScreen ? zoomLevel + 0.14 : zoomLevel,
                  cursor: zoomin ? "-webkit-zoom-in" : "inherit",
                  zIndex: "10",
                  transformOrigin: zoomin ? "" : "0",
                }}
                alt=""
                onClick={zoomin ? handleZoomin : zoomout}
                name={arrImgName[currentIndex]}
              />
            )}

            {/* {isAutoPlayVariable && ( */}
            {isAutoPlay && (
              // <CanvasAutomate imgUrl={'https://comixflipbucket.s3.amazonaws.com/cbzfiles/9780062107619/Page0005.jpg'} />
              <CanvasAutomate
                ref={canvasAutomateRef}
                // imgUrl={arrImg[currentIndex]}
                imgUrl={imgUrl}
                imgCordinates={cordinates}
                onAnimationComplete={handleAnimationComplete}
                FullScreen={FullScreen}
                style={{ transition: "all 3s ease" }}
              />
            )}
            <button
              className="navBtn navBtnRight"
              style={{ width: View2x ? "22%" : "35%" }}
              onClick={goToNext}
            >
              {/* <img src={require("../assets/icons/arrowleftws2.png")} alt='Next' /> */}
              &nbsp;
            </button>
          </div>

          <div ref={thumbnailContainerRef} className="thumbnailContainer">
            {arrImg.map((val, i) => {
              // const [label, values] = Object.entries(val)[0]
              // //console.log(val, i)
              return (
                <div key={i} onClick={() => handleThumbnailClick(i)}>
                  {/* <img src={val.fileUrl} alt={val.fileName} title={val.fileName} onClick={activateThumbnail} /> */}
                  <img
                    className={
                      isBookmarkedPageNo == arrImgName[currentIndex]
                        ? "highlightBookmark"
                        : ""
                    }
                    src={val}
                    href={i}
                    title={i + 1}
                    onClick={activateThumbnail}
                    loading="lazy"
                    name={arrImgName[i]}
                  />
                </div>
              );
            })}
            {/* <p>{props.isbn}</p> */}
          </div>
        </div>
      </div>
      {/* { currentSubtitle ?? ( */}
      {(Sound && currentSubtitle) ?? (
        <div className="CR_subtitle">
          {
            // subtitleText
            subtitleText
            //  voiceContent
          }
        </div>
      )}
      {/* <div className="CR_subtitle">{u}</div> */}

      <Footer />
    </div>
  );
};

export default Reader;
