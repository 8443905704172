import React, { useContext, useState } from 'react'
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BtnGreen from './btnGreen';
import BtnOrange from './btnOrange';
import uploadBook from '../../api/uploadBook';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { API_URL } from "../../api/config";
import axios from 'axios';
import checkISBN from '../../api/checkISBN';
import Lottie from "lottie-react";
import animation1 from "../../assets/animations/uploading.json";
import { toast } from 'react-toastify';
import { CheckContext } from '../../App';
const AddBookComic = (props) => {


    const email = "Hitesh";
    const [file, setFile] = useState([]);
    const [uploaded, setUploaded] = useState(null);
    const [Extension, setExtension] = useState("");
    // const [uploadMethod, setUploadMethod] = useState(`${Extension}`)
    const [check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2] = useContext(CheckContext);

    // const handleFileChange = (e) => {
    const handleFileChange = (e) => {
        //debugger;
        setIsLoading2(true);
        setFile(e.target.files[0]);
        const ISBN = e.target.files[0].name.split(".")[0];
        const fileExtension = e.target.files[0].name.split(".")[1];
        let uploadMethod;
        checkISBN(ISBN)
            .then((res) => {
                setIsLoading2(true)
                if (!res.Check) {
                    toast.error("file already exist with same name on server")
                    // alert("file already exist with same name on server");
                    setIsLoading2(false);
                }
                else {
                    //debugger;
                    setIsLoading2(false);
                    if (fileExtension == "cbz") {
                        //console.log("cbz")
                        setExtension("cbz")
                        // setUploadMethod("single");
                        uploadMethod="single"
                        //console.log("uploadMethod 1", uploadMethod)
                    }
                    else if (fileExtension == "zip"){
                        setExtension("zip")
                        uploadMethod="zip"
                        // setUploadMethod("zip");
                        //console.log("uploadMethod 2", uploadMethod)
                        //console.log("zip")
                    }

                    const formData = new FormData();
                    formData.append('file', e.target.files[0]);
                    formData.append("user", "user");


                    //console.log("uploadMethod 3", uploadMethod)
                    axios
                    .post(`${API_URL}/Comix/rest/upload/${uploadMethod}`, formData, {
                        onUploadProgress: (data) => {
                                //console.log("uploadMethod 4", uploadMethod)
                                // //debugger
                                // console.log(data.loaded, data.total);
                                let percentage = Math.round((data.loaded / data.total) * 100)
                                setUploaded(percentage)
                            },
                        })
                        // console.log(response2)
                        .then((response) => {
                            //debugger

                            if (response) {
                                //debugger;
                                //console.log(response)
                                sessionStorage.clear();
                                toast.success(response.data)
                                setTimeout(() => {
                                    sessionStorage.removeItem(["catalogData", "GetBookYourUploadedCat", "GetBookFavCat", "GetBookNewReleasedCat", "GetBookContReadingCat", "GetBookAuthorCat", "GetBookCharCat", "GetBookAllCat"]);
                                    window.location.reload(true);


                                }, 2000)
                                // alert(response.data)
                                return response.data;

                            } else {
                                throw new Error('Upload failed')
                            }
                        })
                        .then((result) => {
                            //debugger;
                            // alert(result);
                            //console.log(result)
                            setUploaded(null);


                        })
                }
            })
    }
    function resetUploader(e) {
        setFile("");
        setUploaded(null);
        console.log("reseted file input", e.target.files)

    }


    return (
        <div>
            <Modal show={props.methods[2]} onHide={props.methods[1]} id="CR_modalAdd" size="md" className="mt-5" >

                <Modal.Header closeButton onClick={resetUploader}>
                    <Modal.Title className="mb-3"><h3>Add New Book</h3></Modal.Title>
                </Modal.Header>

                <Modal.Body className="py-0">
                    <form>
                        <div className={`d-flex ${Extension ? "justify-content-center" : "justify-content-between"} text-center`}>
                            <Lottie className={`${Extension ? 'd-block' : 'd-none'}`} animationData={animation1} loop={true} style={{ 'width': '300px', 'marginTop': '-50px' }} />
                            <label htmlFor="ABC_File" className={`form-label text-center ${Extension ? "d-none" : ""}`}>
                                <img className="addBookImg" src={require("../../assets/images/Group66.png")} alt="" />
                                <br />

                                <label htmlFor="single" className="pt-2"><b>Single Upload</b> </label>
                            </label>


                            <label htmlFor="ABC_mFile" className={`form-label text-center ${Extension ? "d-none" : ""}`}>
                                <img className="addBookImg" src={require("../../assets/images/Group67.png")} alt="" />
                                <br />
                                {/* {(file.length)>1 ? `file Name- `+ file : ""} */}
                                <label htmlFor="Multiple" className="pt-2" style={{ "marginLeft": "-10px" }}><b>Multiple Upload</b> </label>
                            </label>

                            <input type="file" id="ABC_File" className='d-none' accept=".cbz, .CBZ, .Cbz" onChange={handleFileChange} />
                            <input type="file" id="ABC_mFile" className='d-none' accept=".zip, .Zip, .ZIP, .rar, .7zip" onChange={handleFileChange} multiple />
                        </div>
                        {/* <div className="text-center">
                            {(file.length) > 1 ? `file Name: ` + file : ""}
                        </div> */}
                    </form>

                    <ProgressBar animated now={uploaded ? uploaded : null} style={{ "display": uploaded ? "flex" : "none" }} />
                    <div className={`text-center d-${uploaded ? "flex" : "none"} justify-content-center`}>{`${uploaded}%`}</div>

                </Modal.Body>

                {/* <Modal.Footer className="mt-3">
                    <BtnGreen text="Upload" click={sumitComic} />
                    <BtnOrange text={"Cancel"} click={cancelComic} />
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}

export default AddBookComic