import React, { useState, useEffect, useRef } from 'react';

const SearchComponent = () => {
  const [keyword, setKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const abortControllerRef = useRef(new AbortController());
  const cooldownTimeoutRef = useRef(null);

  const debounce = (func, delay) => {
    let timeoutId;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      abortControllerRef.current.abort(); // Abort the previous request
      abortControllerRef.current = new AbortController();

      const response = await fetch('http://comixflip.nexorityinfotech.com:8080/Comix/rest/B/main', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            keyword: keyword,
          },
          method: 'searchComic',
        }),
        signal: abortControllerRef.current.signal, // Attach the signal to the request
      });

      if (!response.ok) {
        console.error('Failed to fetch data');
        return;
      }

      const data = await response.json();
      setSearchResults(data.data || []); // Initialize with an empty array if results are undefined
      console.log(data.data)
    } catch (error) {
      if (error.name === 'AbortError') {
        // Ignore the abort error
      } else {
        console.error('Error during fetch:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  const delayedSearch = debounce(() => {
    handleSearch();
  }, 500); // Adjust the delay based on your preference

  const handleInputChange = (e) => {
    let newKeyword = e.target.value;

    setKeyword(newKeyword);

    // Clear the cooldown timeout and restart it
    clearTimeout(cooldownTimeoutRef.current);
    cooldownTimeoutRef.current = setTimeout(() => {
      delayedSearch();
    }, 1000); // Adjust the cooldown duration based on your preference

        // Check if the input is empty and clear the search results
        if (newKeyword.trim() === '') {
          // debugger;
          // setKeyword('');
          setSearchResults([]);
          // newKeyword='';
        }
    
  };

  useEffect(() => {
    // Cleanup function to clear the cooldown timeout
    return () => {
      clearTimeout(cooldownTimeoutRef.current);
    };
  }, [searchResults]);

  return (
    <div>
      <input
        type="text"
        value={keyword}
        onChange={handleInputChange}
        placeholder="Enter keyword"
      />

      {/* Display loading indicator while fetching */}
      {loading && <p>Loading...</p>}

      {/* Display search results */}
      {Array.isArray(searchResults) && searchResults.length > 0 ? (
      <ul>
      
        {
        searchResults.map((result) => (
          <li key={result["Book Title"]}>{result["Book Title"]}</li>
          // Adjust this based on your API response structure
        ))}
 
      </ul>
      ): (
        <p style={{display: searchResults ? "block":"none"}}>{searchResults}</p>
      )}
    </div>
  );
};

export default SearchComponent;






