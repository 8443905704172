import { API_URL } from "./config"

export default (email, password) => {
    // //debugger;
    // return fetch(`${API_URL}/Comix/rest/B/main`, {
    return fetch(`${API_URL}/Comix/rest/logIn/validate`, {
        method: 'POST',
        headers: {
            "Content-Type": 'application/json',
            // "Accept-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Origin": "*",
            // "Access-Control-Allow-Methods": "POST",
            // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"

        },
        body: JSON.stringify({

            "data": {
                "email": email,
                "password": password
            },
            "method": "loginValidation"

        })
    })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Login failed');

            }
        })
}
// const myUploads = [{
//     "title": "Immortal X-Men",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men",
//     "author": "Dennis Richie",
//     "genre": "Action",
//     "series": "X-Men",
//     "star": "1 Star",
//     "url": "https://www.ndtv.com/india-news/amritpal-singh-updates-cops-intensify-ops-to-arrest-khalistani-leader-internet-cut-in-punjab-3876915",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// }, {
//     "title": "Immortal X-Men 22",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men22",
//     "author": "Dennis Richie22",
//     "genre": "Action22",
//     "series": "X-Men22",
//     "star": "2 Star",
//     "url": "assets/images/Rectangle8.png",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// },
// {
//     "title": "Immortal X-Men 33",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men33",
//     "author": "Dennis Richie33",
//     "genre": "Action33",
//     "series": "X-Men33",
//     "star": "3 Star",
//     "url": "assets/images/Rectangle8.png",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// },
// {
//     "title": "Immortal X-Men 44",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men44",
//     "author": "Dennis Richie442",
//     "genre": "Action44",
//     "series": "X-Men44",
//     "star": "4 Star",
//     "url": "assets/images/Rectangle8.png",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// },
// {
//     "title": "Immortal X-Men 55",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men55",
//     "author": "Dennis Richie55",
//     "genre": "Action55",
//     "series": "X-Men55",
//     "star": "5 Star",
//     "url": "assets/images/Rectangle8.png",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// },
// {
//     "title": "Immortal X-Men 66",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men66",
//     "author": "Dennis Richie66",
//     "genre": "Action66",
//     "series": "X-Men66",
//     "star": "6 Star",
//     "url": "assets/images/Rectangle8.png",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// },
// {
//     "title": "Immortal X-Men 77",
//     "isbn": "85547998888865",
//     "publisher": "Immortal X-Men77",
//     "author": "Dennis Richie77",
//     "genre": "Action77",
//     "series": "X-Men77",
//     "star": "7 Star",
//     "url": "assets/images/Rectangle8.png",
//     "urlToImage": "assets/images/Rectangle8.png",
//     "publishedAt": "2023-03-20T14:20:00Z"

// }

// ];
// export const getDashboard = () => {
//     return fetch(`${API_URL}/Comix/rest/B/main`, {
//         method: 'POST',
//         headers: {
//             "Content-Type": 'application/json',
//             "check": localStorage.getItem("check")
//         },
//         body: JSON.stringify({

           
//             "data": {
//             },
//             "method": "getDashboard"


//         })
//     })
//         .then(response => {
//             if (response.ok) {
//                 return response.json()
//             } else {
//                 throw new Error('Some Error Occured... Please contact Administrator')
//             }
//         })



//     // return Promise.resolve(myUploads)

// }