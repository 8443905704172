// import logo from './logo.svg';
import './App.css';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import LoginForm from './pages/LoginForm';
import { CatalogAll } from './pages/catalog-all';
import { Catalog } from './pages/catalog';
import { LoginFormAdmin } from './pages/LoginFormAdmin';
import { ResetPasswordForm } from './pages/resetPasswordForm';
import ErrorPage from './pages/404';
import { Reader } from './pages/reader';
import Preloader from './components/preloader';
import AlertD from './components/subComonents/alertD';
import './assets/css/main.css';
import './assets/font-awesome/css/all.css';
import './assets/font-awesome/css/fontawesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Preloader2 from './components/subComonents/Preloader2';
import { TxtSpeech } from './pages/txtSpeech';
import SearchComponent from './pages/SearchComponent';
// import { SearchComponent } from './pages/SearchComponent';
// import { Preloader2 } from './components/subComonents/preloader2';
// import { ToastContainer } from 'react-toastify';
// import Toastify from './components/subComonents/Toastify';


export const CheckContext = React.createContext(null);
export const userDetailsContext = React.createContext(null);
export const PreloaderContext = React.createContext(null);
export const searchContext = React.createContext(null);
// export const toastContext = React.createContext(null);

// const ProtectedRoute = ({ element }) => {
//   const [check] = useContext(CheckContext);
//   return check ? element() : <Navigate to="/" />;
// }

const ProtectedRoute = ({ element }) => {
  const Component = element;
  const [check] = useContext(CheckContext);
  const [userDetails] = useContext(userDetailsContext);
  // const [toast] = useContext(toastContext);
  return check ? <Component /> : <Navigate to="/" />;
}
function App() {

  const [check, setCheck] = useState(localStorage.getItem("check") || null);
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("User")) || null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);


  const [keyword, setKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  //debugger



  return (
    <Router>
      <div>
        <CheckContext.Provider value={[check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2]}>
          <userDetailsContext.Provider value={[userDetails, setUserDetails]}>
          <searchContext.Provider value={[keyword, setKeyword, searchResults, setSearchResults]}>
            {/* <toastContext.Provider value={[toast, setToast, showToast]}> */}

            {/* <Toastify toast={toast} /> */}

            <Preloader isLoading={isLoading} />
            <Preloader2 isLoading2={isLoading2} />
            {/* <AlertD variant={"danger"} text={"This is alert"} link={"Link Demo"}/> */}
            <Routes>
              <Route exact path='/' element={<LoginForm />} />
              <Route exact path='/catalog' element={<ProtectedRoute element={Catalog} />} />
              <Route exact path='/all' element={<ProtectedRoute element={CatalogAll} />} />
              <Route exact path='/reader' element={<ProtectedRoute element={Reader} />} />
              <Route exact path='/signup' element={<ProtectedRoute element={LoginFormAdmin} />} />
              <Route exact path='/txtspeech' element={<ProtectedRoute element={TxtSpeech} />} />
              <Route exact path='/SearchComponent' element={<ProtectedRoute element={SearchComponent} />} />
              {/* <Route exact path='/resetPassword' element={ResetPasswordForm} /> */}
              <Route path='*' element={<ErrorPage />} />
            </Routes>
            {/* </toastContext.Provider> */}
          </searchContext.Provider>
          </userDetailsContext.Provider>
        </CheckContext.Provider>

      </div >
    </Router >




  );
}

export default App;
