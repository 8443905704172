import { API_URL } from "./config";

export default async (ISBN, PageName, lang) => {
    debugger
    const response = await fetch(`${API_URL}/Comix/rest/B/main`, {
        method: "POST",
        headers: {
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(
          
            {
                "data": {
                    "ISBN": ISBN,
                    "pageName": PageName,
                    "toLanguage": lang
                },
                "method": "getConversation"
            }

        )
    })
    // .then(response => {
        if (response.ok) {
            return response.json()
        } else {
            throw new Error('Failed to get conversation... Please contact administrator');

        }
    // });

  
}