import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CardBoot from "../components/subComonents/cardBoot";
import { getCategories } from "../api/loginRequest";
import { Link, useLocation } from "react-router-dom";
import GetBooksAll from "../api/GetBooksAll";
import { CheckContext, searchContext, userDetailsContext } from "../App";
import Navbar2 from "../components/navbar2";
import Preloader2 from "../components/subComonents/Preloader2";
import Preloader3 from "../components/subComonents/Preloader3";
import { ToastContainer } from "react-toastify";
import debounce from "lodash.debounce";
import GetBooksContReading from "../api/GetBooksContReading";

// import { CheckContext } from '../App';

// function CatalogAll(props) {
export const CatalogAll = (props) => {
  const [check, setCheck, isLoading, setIsLoading, isLoading2, setIsLoading2] =
    useContext(CheckContext);
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [dashboard, setDashboard] = useState([]); //1
  const [error, setError] = useState("");
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [grayscale, setGrayscale] = useState("false");
  const userRole = userDetails?.data?.roles;
  const userEmail = userDetails?.data?.email;
  const [removeOverlay, setRemoveOverlay] = useState(false);
  const pageContainerRef = useRef(null);
  // ontainerRef = useRef(null);
  const mediaContainerRef = useRef(null);
  const [pNoReq, setPNoReq] = useState(0);
  const [keyword, setKeyword, searchResults, setSearchResults] =
    useContext(searchContext);
  const [searchResultStatus, setSearchResultStatus] = useState(false); //1
  // let searchResultStatus=false;

  // const [isLoading3, setIsLoading3] = useState(false);
  // const [check, setCheck, isLoading, setIsLoading] = useContext(CheckContext);
  // const [catalog, setCatalog] = useState([]); //1

  // useEffect(() => { //3
  //     getCategories().then((res) => {  //4
  //         //debugger;
  //         setCatalog(res);  //5
  //         // setIsLoading(false);
  //     })
  // }, [])

  //2
  // const cardImgHeight = {

  //         "minHeight": "250px",
  //         "maxHeight": "250px"

  // }
  // let pNoReq = 0;
  function getApitData(APIMethod, method, cookieName) {
    // const cachedData = sessionStorage.getItem(cookieName);

    // if (cachedData) {
    //     //debugger
    //     setDashboard(JSON.parse(cachedData));
    //     setIsLoading2(false);

    // }
    // else {
    // alert(searchTerm);
    //console.log("searchTerm", searchTerm);
    method(APIMethod, pNoReq, 20, userEmail)    
      .then((res) => {
        //debugger;
        setIsLoading2(true);
        setDashboard((prevData) => [...prevData, ...res.data]);
        sessionStorage.setItem(cookieName, JSON.stringify(res.data));
        //console.log("dashboard", dashboard);
        setPNoReq(pNoReq + 1);
        setIsLoading2(false);
      })
      .catch((err) => {
        setError(err);
        console.log("error", error);
      });

    // }
  }

  const getDataDynamicMethod = () => {
    if (searchTerm == "Your Uploaded") {
      getApitData("getAllComics", GetBooksAll, "GetBookYourUploadedCat");
    } else if (searchTerm == "New Released") {
      getApitData("getAllComics", GetBooksAll, "GetBookNewReleasedCat");
    } else if (searchTerm == "Continue Reading") {
      getApitData("getContinueReading", GetBooksContReading, "GetBookContReadingCat");
    } else if (searchTerm == "Author") {
      getApitData("getAllComics", GetBooksAll, "GetBookAuthorCat");
    } else if (searchTerm == "Character") {
      getApitData("getAllComics", GetBooksAll, "GetBookCharCat");
    } else if (searchTerm == "Your favourite") {
      getApitData("getAllComics", GetBooksAll, "GetBookFavCat");
    } else if (searchTerm == "All") {
      getApitData("getAllComics", GetBooksAll, "GetBookAllCat");
    }
  };

  // let scrolled = false;
  //   const handleScrollCheck = () => {
  //     debugger;
  //     const divElement = pageContainerRef.current;
  //     if (divElement) {
  //       const isFullyScrolled =
  //         divElement.scrollTop + divElement.clientHeight >=
  //         divElement.scrollHeight;
  //       if (
  //         divElement.scrollTop + divElement.clientHeight + 2 >=
  //         divElement.scrollHeight
  //       ) {

  //         setPNoReq(pNoReq + 1);
  //         setIsLoading2(true);

  //         getDataDynamicMethod();
  //         console.log("Is Fully Scrolled:", isFullyScrolled);
  //       }
  //     }
  //   };

  const handleScroll = debounce(() => {
    const isScrolledToBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;
    if (isScrolledToBottom && !isLoading2) {
      getDataDynamicMethod();
    }
  }, 800);

  // pageContainerRef.scrollTop(100);
  // const er = document.getElementsByClassName('pageContainer');
  // er.scrollTop=20;

  // er.addEventListener('click', function (e) {
  //     alert("d")
  //  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const searchTerm = queryParams.get("cat");

  
  useEffect(()=>{
    hideSearchDiv();
  },[])
    useEffect(() => {
      if (searchResults.length > 0) {
        setSearchResultStatus(true);
        console.log(searchResultStatus);
      }
      else{
        setSearchResultStatus(false);
        hideSearchDiv();
  
      }
    }, [searchResults]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [pNoReq, isLoading2]);
  useEffect(() => {
    // setIsLoading2(true);
    getDataDynamicMethod();
  }, []);

  const hideSearchDiv = ()=>{
    setKeyword("");
    setSearchResults("");
    setSearchResultStatus(false);
  }
  const cancelSynthesis = () => {
    const synth = window.speechSynthesis;
    synth.cancel();
  };
  useEffect(() => {
    cancelSynthesis();
});

  return (
    <div>
      <Navbar2 />
      <div
        ref={pageContainerRef}
        className="pageContainer"
        onScroll={handleScroll}
      >
        <ToastContainer position="top-right" autoClose={5000} />
      
      
        {searchResultStatus && (
          <div
            className=""
            style={{
              position: "fixed",
              background: "white",
              height: "90vh",
              width: "100vw",
              top: "6vh",
              left: "0",
              zIndex: "8",
              overflow: "auto",
              display: searchResultStatus ? "block" : "none"
            }}
          >
         
            <div className="pt-5 pb-5 mt-4">
            
              {Array.isArray(searchResults) && searchResults.length > 0 ? (
                <div class="container px-sm-4 book_row mt-2 searchRow">
               <div className="d-flex justify-content-between align-items-center" >
                    <div className="d-flex gap-3">
                      <h5 className="mb-3" style={{textTransform: "capitalize"}}>Search Result</h5></div>
                      {/* <button class="btn btn_viewAll" onClick={hideSearchDiv}>Close Search</button> */}
                  </div>
                <div className="CatalogGrid8">

               


                  {searchResults.map((val, i) => (
                    <div key={i}>
                      <CardBoot
                        urlToImage={val["Cover Page"]}
                        title={val["Book Title"]}
                        isbn={val.isbn}
                        publisher={val.publisher}
                        author={val.Authors[0]["First Name"]}
                        ROverlay={null}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                      />
                    </div>

                  ))}
                </div>
                </div>
              ) : (
                <div class="container px-sm-4 book_row mt-2">
                <p style={{ display: searchResults ? "block" : "none" }}>
                  {searchResults}
                </p>
                </div>
              )}
          
            </div>

            {/* })} */}
          </div>
        )}
      
      
      
      
      
        <div className="mt-4">
          <div>
            <div className="container book_row px-sm-4  my-3">
              <div className="d-flex flex-column justify-content-between align-items-start mb-2">
                <h5 style={{ textTransform: "capitalize" }}>{searchTerm}</h5>
                {/* <Preloader3 isLoading3={isLoading3} /> */}
                {/* <button className="btn">View All</button> */}
                {/* <Link to="/all" className="btn btn_viewAll">View All</Link> */}
              </div>
              <div className="CatalogAllGrid8 g-1">
               
               
               
               
                {dashboard.map((val, i) => {
                  const [label, values] = Object.entries(val)[0];
                  // //debugger;
                  return (
                    <div key={i}>
                      {/* <CardBoot imageStyle={cardImgHeight} urlToImage={val["Cover Page"]["$binary"].base64} title={val["Book Title"]} isbn={val.isbn} publisher={val.publisher} author={val.Authors[0]["First Name"]} ROverlay={label === "Your Uploaded" ? removeOverlay : null} deleteCheck={deleteCheck} selectedItems={selectedItems} setSelectedItems={setSelectedItems} grayscale={grayscale} setGrayscale={setGrayscale} /> */}
                      <CardBoot
                        urlToImage={val["Cover Page"]}
                        title={val["Book Title"]}
                        isbn={val.isbn}
                        publisher={val.publisher}
                        author={val.Authors[0]["First Name"]}
                        ROverlay={
                          label === "Your Uploaded" ? removeOverlay : null
                        }
                        deleteCheck={deleteCheck}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        grayscale={grayscale}
                        setGrayscale={setGrayscale}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-between">
                    <button className='btn' onClick={prevResult}>Prev</button>
                    <button className='btn' onClick={nextResult}>Next</button>
                </div> */}
      </div>

      <Footer />
    </div>
  );
};

// export default CatalogAll
