import React, { useEffect, useState } from 'react'
import { Prev } from 'react-bootstrap/esm/PageItem';
import { Link, useNavigate } from 'react-router-dom';

function CardBoot(props) {
    const [hideCard, sethideCard] = useState(false);
    const [grayscale, setGrayscale] = useState(false);
    const [deleteCheck, setDeleteCheck] = useState(false);
    const { selectedItems, setSelectedItems } = props
    const navigate = useNavigate();
    const propValue1 = 'Value 111';
    const propValue2 = 'Value 222';
    const isbnp = props.isbn;
    const publisherp = props.publisher;
    //console.log("selectedItems", selectedItems)
    const handleCheckboxChange = (itemId) => {

        // //debugger
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        } else {

            setSelectedItems(prev => [...prev, itemId]);

        }
    };




    function thisLandingPage() {

    }
    const twoCalls = e => {
        // selectedItems.includes(props.isbn)
        handleCheckboxChange(props.isbn);
        removeComixGrayscale(e)
    }

    function removeComixGrayscale(e) {
        //debugger;
        setDeleteCheck(e.target.checked);

        // if (e.target.parentNode.parentNode.parentNode.parentNode.parentNode.previousSibling.getAttribute("id") === "Your Uploaded") {
        if (!deleteCheck && !grayscale) {
            setGrayscale(true);
            e.target.parentNode.parentNode.parentNode.classList.add("fadeCard");

            setDeleteCheck(true);
            // props.cancelDelete(true);
        }
        else {
            setDeleteCheck(props.deleteCheck);
            setGrayscale(false);
            e.target.parentNode.parentNode.parentNode.classList.remove("fadeCard");
        }

        sethideCard(true);
        // }
    }
    function jumpToReader(e) {
        navigate('/reader')

        // console.log(isbn2, "isbn2")

        // console.log(props.isbn)
    }

    return (
        <div className={`card shadow rounded `} onClick={thisLandingPage} >
            {/* <img src={`data:image/png;base64,${props.urlToImage}`} style={props.imageStyle} className="card-img" alt="..." /> */}
            <img src={`${props.urlToImage}`} className="card-img" alt="..." />
            {/* <img src={props.urlToImage} className="card-img" alt="..." /> */}

            {/* front/ add overlay */}
            <div className={`card-img-overlay`}>
                <h6 className="card-title text-center">{props.title}</h6>
                <table className="table card_overlay">
                    <tbody>
                        <tr>
                            <td width={"30%"}>ISBN:</td>
                            <td width={"70%"}>{props.isbn}</td>
                        </tr>
                        <tr>
                            <td>Publisher:</td>
                            <td>{props.publisher}</td>
                        </tr>
                        <tr>
                            <td>Author:</td>
                            <td>{props.author}</td>
                        </tr>
                        {/* <tr>
                            <td>Genre:</td>
                            <td>{props.genre}</td>
                        </tr>
                        <tr>
                            <td>Series</td>
                            <td>{props.series}</td>
                        </tr> */}
                        <tr style={{ 'display': 'none' }}>
                            <td colSpan={2} className="text-center">{props.star}</td>

                        </tr>
                        <tr>
                            <td colSpan={2} rowSpan={2} className="text-center">

                                {/* <Link className="navbar-brand" to={"/reader"} > */}
                                <Link className="navbar-brand" to={'/reader'}
                                    state= {{isbnp: isbnp, publisherp: publisherp}}
                                >
                                    {/* <p>{propValue1}</p> */}
                                    {/* <p>{isbnp}</p> */}
                                    <img style={{ "margin": "5px 0" }} src={require("../../assets/icons/icon(26).png")} />
                                </Link>
                                {/* <img style={{ "margin": "5px 0" }} src={require("../../assets/icons/icon(26).png")} onClick={jumpToReader} /> */}

                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>

            {/* back/delete overlay */}
            {/* {label==="Your Uploaded" ? removeOverlay: ""}  */}
            <div className={`card-img-removerOverlay ${props.ROverlay ? "showRemoveOverlay" : ""}`}>
                <h6 className="card-title text-center">{props.title}</h6>
                <div className="card_overlay d-flex justify-content-center align-items-center h-100">
                    {/* <input type="checkbox" onChange={() => handleCheckboxChange(props.isbn)} checked={selectedItems.includes(props.isbn)} style={{ height: "30px" }} /> */}
                    <input type="checkbox" onChange={twoCalls} checked={selectedItems.includes(props.isbn)} style={{ height: "30px", position: "absolute", bottom: "15px" }} />
                    {/* <label>{props.isbn} </label> */}
                    {/* <img src={require("../../assets/icons/delete.png")} alt="Delete icon" onClick={removeComixGrayscale} /> */}
                </div>






            </div>
        </div>


    )


}




CardBoot.defaultProps = {
    name: "Rahul",
    eyeColor: "deepblue",
    age: "45"
}

export default CardBoot