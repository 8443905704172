import React, { useState, useEffect } from 'react';
export const TxtSpeech = () => {
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [voiceText, setVoiceText] = useState('welcome hitesh');

    useEffect(() => {
        const onVoicesChanged = () => {
            const voices = window.speechSynthesis.getVoices();
            setVoices(voices);
            setSelectedVoice(voices[0]);
        };

        window.speechSynthesis.addEventListener('voiceschanged', onVoicesChanged);


        return () => {
            window.speechSynthesis.removeEventListener('voiceschanged', onVoicesChanged);
        };
    }, []);

    const handleVoiceChange = (event) => {
        const voiceName = event.target.value;
        const voice = voices.find((v) => v.name === voiceName);
        setSelectedVoice(voice);
    };

    const handleVoiceTextChange = (event) => {
        setVoiceText(event.target.value);
    };

    const handleSpeak = () => {
        if (voiceText && selectedVoice) {
            const utterance = new SpeechSynthesisUtterance(voiceText);
            utterance.voice = selectedVoice;
            window.speechSynthesis.speak(utterance);
        }
    };

    return (
        <div className='container'>
            <select onChange={handleVoiceChange}>
                {voices.map((voice) => (
                    <option key={voice.name} value={voice.name}>
                        {voice.name}
                    </option>
                ))}
            </select>




            <textarea value={voiceText} onChange={handleVoiceTextChange} />
            <button onClick={handleSpeak}>Speak</button>
        </div>
    );
};

