import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import React from 'react'

// const notify = "This is alert"
// const Toasty = () => {
//     return (
//         <div>
//             <button onClick={notify}>Notify !</button>
//             <ToastContainer />
//         </div>
//     )
// }

function Toastify(props) {

  // let notify

    let notify;
  // function fff() {
  //     //debugger;
  //     if (props.msg && props.type == "info") {
  //        notify = () => toast.info(props.msg);
  //       notify();
  //     }

  //     else if (props.type === "success") {
  //       notify = () => toast.success(props.msg);
  //       notify();
  //     }
  //     else if (props.type === "warning") {
  //        notify = () => toast.warning(props.msg);
  //       notify();
  //     }
  //     else if (props.type === "error") {
  //       notify = () => toast.error(props.msg);
  //       notify();
  //     }
  //     else {

  //        notify = () => toast(props.msg);
  //       notify();

  //     }
  //   }



  // fff();



  // }, [])



  // if(props.msg){
  //     showToast();
  //   }
  return (

    <div>
      {/* <button onClick={fff}>{props.type}</button> */}
      <ToastContainer position={props.position} autoClose={5000} />
    </div>
  )
}
export default Toastify
